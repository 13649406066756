import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fontWeight } from '../../../core/constants/constants';

@Component({
  selector: 'app-four-inputs-dialog',
  templateUrl: './four-inputs.component.html',
  styleUrls: ['./four-inputs.component.scss'],
})
export class FourInputsComponent implements OnInit {

  @Input()
  public form!: any

  @Input()
  public formGroupNameValue!: string;

  @Input()
  public isUpperCaseShown: boolean = true;

  @Input()
  public title!: string;

  @Input()
  public fontFamilyControlName: string = "";

  @Input()
  public fontColorControlName: string = "";

  @Input()
  public fontWeightControlName: string = "";

  @Input()
  public fontSizeControlName: string = "";

  @Input()
  public state: any;

  @Input()
  public selectFieldOptions!: any[];

  @Input()
  public colorPickerPosition!: string;

  public fontWeight = fontWeight;

  constructor(private parentFormGroup: FormGroupDirective) { }

  ngOnInit() {
  }

}
