import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  public currentMediaManager: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentMediaManager$: Observable<any> = this.currentMediaManager.pipe(shareReplay(1));
  public mediaInstanceId: string = '';
  public currentMediaInstanceName = '';

  constructor(
    private http: HttpClient,
  ) { }

  private getMediaManager() {
    return this.http.get(`${environment.apiUrl}/${this.currentMediaInstanceName}`);
  }

  private createMediaManagerInstance() {
    return this.http.post(`${environment.apiUrl}${this.currentMediaInstanceName}/default`, null)
  }

  public getMediaContent(fileId: string) {
    return this.http.get(`${environment.apiUrl}Media/entity/${this.currentMediaManager.getValue().id}/file/${fileId}`)
  }

  public saveCarousel(items: any) {
    return this.http.put(`${environment.apiUrl}${this.currentMediaInstanceName}/${this.currentMediaManager.getValue().id}/carousel`, items)
  }
  public getCarouselItems() {
    return this.http.get(`${environment.apiUrl}${this.currentMediaInstanceName}/${this.currentMediaManager.getValue().id}/carousel`)
  }

  public addItemsToMediaManager(items: any) {
    return this.http.put(`${environment.apiUrl}${this.currentMediaInstanceName}/${this.currentMediaManager.getValue().id}`, items)
  }


  public getMediaManagerInstance() {
    return this.getMediaManager().pipe(
      switchMap((result) => {
        if (!(result as any).length) {
          return this.createMediaManagerInstance();
        } else {
          return of(result)
        }
      })
    )
  }

  public uploadFile(fileObject: any, mediaObjectId: string) {
    const formData = new FormData();
    formData.append('files', fileObject.data);
    fileObject.inProgress = true;
    return this.http.post<any>(`${environment.apiUrl}/Media/entity/${mediaObjectId}`, formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'json',
    }).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileObject.progress.next(Math.round(event.loaded * 100 / event.total))
            break;
          case HttpEventType.Response:
            return event.body[0];
        }
      }),
      catchError((error: HttpErrorResponse) => {
        fileObject.inProgress = false;
        return of(null);
      })
    );
  }
}

