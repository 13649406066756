import { Component, OnInit } from '@angular/core';
import { navigationElements } from '../../constants/constants';
import { SidebarService } from '../../services/sidebar.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public userData: any;
  public navigationElements = navigationElements;

  constructor(
    public sidebarService: SidebarService,
    private readonly authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userData = this.authService.userData;
  }

  hasAnyPermission(permissions?: (string | string[])[]): boolean {
    return permissions ? permissions.some(perm => {
      if (Array.isArray(perm)) {
        return perm.every(p => this.authService.userPermissionsData.includes(p));
      } else {
        return this.authService.userPermissionsData.includes(perm);
      }
    }) : true;
  }

  public isGroupSelected(item: any) {
    const routeName = this.router.url;
    let isExpanded = false;
    if(!!item.url) {
      isExpanded = routeName.includes(item.url);
      return isExpanded;
    }
    item.forEach((section: any) => {
      const currentRoute = !!section.routes.find((r: any) => routeName.includes(r.url)  );
      if (currentRoute) {
        isExpanded = currentRoute;
        return;
      }
    });
    return !!isExpanded
  }
}
