import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MediaService } from '../../services/media.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-background-input',
  templateUrl: './background-input.component.html',
  styleUrls: ['./background-input.component.scss'],
})
export class BackgroundInputComponent {

  @ViewChild("input", { static: false })
  public get fileInput() {
    return this._fileInput;
  }

  public set fileInput(v) {
    this._fileInput = v;
  }

  private _fileInput: any;

  @Output()
  public onValueChange: EventEmitter<any> = new EventEmitter();

  @Input()
  public title: string | undefined;

  @Input()
  public state: any;

  @Input()
  public colorPropertyPath!: string;

  @Input()
  public imagePropertyPath!: string;

  @Input()
  public uniqClass!: string;

  @Input()
  public showOtherControls: boolean = true;

  @Input()
  public showColorPicker: boolean = true;

  @Input()
  public imageList: any[] = [];

  @Input()
  public colorPickerPosition: 'top' | 'right' | 'left' | 'auto' = 'auto';

  @Input()
  public customCallback: any;

  @Input()
  form: any;

  public file: any;

  constructor(
    private mediaService: MediaService,
    private readonly cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) { }

  public previewFile(): void {
    const anchor = document.createElement('a');
    const url = this.form.get('imageLink').value;
    anchor.href = url;
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  public downloadFile(): void {
    const url = this.form.get('imageLink').value;

    fetch(url).then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        
        return response.blob();
      }).then((blob) => {
        const anchor = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
  
        anchor.href = objectUrl;
        anchor.download = this.form.get('imageName').value;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
      }).catch((error) => {
        console.error('Error downloading file:', error);
      });
  }

  public onFileChange = async (pFileList: any | null, propertyName?: string | undefined) => {
    if (!propertyName) {
      return;
    }
    if (!!pFileList.currentTarget) {
      pFileList = pFileList.currentTarget.files;
    }
    if (!!this.form.get(propertyName)) {
      this.form.get('imageName').setValue(pFileList[0].name);
      this.file = {
        fileName: pFileList[0].name,
        contentType: pFileList[0].type,
        data: pFileList[0],
        inProgress: false,
        progress: new BehaviorSubject(0),
      }
      if (!!this.customCallback) {
        await this.customCallback();
      }
  
      const { mediaFile } = await lastValueFrom(this.mediaService.uploadFile(this.file, this.mediaService.mediaInstanceId));
      this.form.get(propertyName).setValue(mediaFile.fileUrl);
      this.form?.get('fileId')?.setValue(mediaFile.fileId);
      (this.form as FormGroup).markAsDirty();
      this.onValueChange.emit();
      this.cdr.detectChanges();
    }
  }

  private declineFileUpload(msg: string, fileList: any[], fileIndex: number) {
    this.openSnackBar(msg);
    fileList.splice(fileIndex, 1);
    this._fileInput.nativeElement.value = '';
  }

  public async addImageToList(event: any): Promise<void> {
    const { currentTarget } = event;
    const uploadedFiles: any = [];
    const fileList: File[] = !!currentTarget ? Array.from(currentTarget.files) : Array.from(event);
    const maxAllowedSize = 10 * 1024 * 1024;

    fileList.forEach(((file: any, index) => {
      if (!file.size) {
        const msg = `File with name: ${file.name} should be more than 0Kb`;
        this.declineFileUpload(msg, fileList, index)
        return;
      }
      if (file.size > maxAllowedSize) {
        const msg = `File with name: ${file.name} should be less than 10Mb`
        this.declineFileUpload(msg, fileList, index);
        return;
      }
      const existingFile = this.imageList.find(img => img.fileName === file.name);
      if (!!existingFile) {
        const msg = `File with name: ${file.name} already exists`
        this.declineFileUpload(msg, fileList, index);
        return;
      }
    }));
    fileList.forEach(async (file) => {
      uploadedFiles?.push({
        fileName: (file as File).name,
        contentType: (file as File).type,
        data: file,
        inProgress: false,
        progress: new BehaviorSubject(0),
      });
    });
    this.imageList?.push(...uploadedFiles);
    this.onValueChange.emit(uploadedFiles);
    this._fileInput.nativeElement.value = '';
  }

  public deleteFile(propertyName: any) {
    if (this.file) {
      this.file.inProgress = false;
    }
    this.form.get(propertyName).setValue(null);
    this.form.get('imageName').setValue(null);
    this.form?.get('fileId')?.setValue('');
    (this.form as FormGroup).markAsDirty();
  }

  openSnackBar(msg: string) {
    this.snackBar.open(msg, 'Cancel', { panelClass: 'error', duration: 2000, horizontalPosition: 'right', verticalPosition: 'top' });
  }

}
