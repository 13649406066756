import { Component } from '@angular/core';
import { IconService } from '../../../shared/services/icon.service';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  constructor(private iconService: IconService, public sidebarService: SidebarService) {
    this.iconService.registerIcons();
  }
}
