import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalVariablesModule } from './global-variables/global-variables.module';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
    GlobalVariablesModule,
    CoreModule,
],
  declarations: [

  ],
  exports:[GlobalVariablesModule],
})
export class FeatureModule { }
