<query-builder [(ngModel)]='query' [config]='config'>
  <ng-container *querySwitchGroup="let ruleset">
    <div class="radio">
      <button [ngStyle]="{'background': ruleset.condition === 'and' ? 'orange' :''}" mat-button
        (click)="ruleset.condition ='and'">And</button>
      <button [ngStyle]="{'background': ruleset.condition === 'or' ? 'orange' :''}" mat-button
        (click)="ruleset.condition ='or'">Or</button>
    </div>
  </ng-container>
  <ng-container
    *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
    <button type="button" mat-button (click)="addRule()">+ Rule</button>
    <button type="button" mat-button (click)="addRuleSet()">+ Ruleset</button>
    <button type="button" mat-button (click)="removeRuleSet()">- Ruleset</button>
  </ng-container>
  <ng-container class="query-filed-set">
    <ng-container *queryField="let rule; let fields=fields; let onChange=onChange">
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
          <mat-option *ngFor="let field of fields" [value]="field.value">{{field.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *queryOperator="let rule; let operators=operators">
      <mat-form-field appearance="outline" style="width:132px">
        <mat-select [(ngModel)]="rule.operator">
          <mat-option *ngFor="let value of operators" [value]="value">{{value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!-- Override input component for 'boolean' type -->
    <ng-container *queryInput="let rule; type: 'boolean'">
      <mat-checkbox [(ngModel)]="rule.value"></mat-checkbox>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'boolean'">
      <mat-checkbox [(ngModel)]="rule.value"></mat-checkbox>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'string'">
      <mat-form-field appearance="outline" style="width:132px">
        <mat-label>Meaning</mat-label>
        <input formControlName="sectionTitle" matInput type="text">
      </mat-form-field>
    </ng-container>
    <!-- Override input component for 'category' type -->
    <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'">
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="rule.value" [placeholder]="field.name">
          <mat-option *ngFor="let opt of options" [value]="opt.value">
            {{ opt.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
      <button type="button" mat-icon-button color="accent" (click)="removeRule(rule)">
        <mat-icon>remove</mat-icon>
      </button>
    </ng-container>
  </ng-container>

</query-builder>