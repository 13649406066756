import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, first, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userWithPermission } from '../../shared/models/permission';
import { PermissionEnum } from '../../shared/models/permission.enum';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticatedUser: BehaviorSubject<any> = new BehaviorSubject({});
  public refreshToken: string = '';
  private authUrl = environment.authUrl + '?clientId=touchPoint';

  constructor(
    private http: HttpClient,
    private ngxPermissionsService: NgxPermissionsService
  ) {
    this.checkLocalStorage();
    this.tokenExpirationObservable();
  }

  public goToLogin(url: string): void {
    localStorage.setItem('directUrl', url);
    window.location.href = `${this.authUrl}&redirectUrl=${window.location.origin}/auth`;
  }

  public get isUserLoggedIn(): boolean {
    return !!this.authenticatedUser.getValue()?.access_token;
  }

  public set userData(userInfo: any) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  }

  public get userData() {
    return JSON.parse(localStorage.getItem('userInfo')!);
  }

  public get userPermissionsData() {
    return JSON.parse(localStorage.getItem('userPermissions')!);
  }

  public isUserInfoExpired() {
    if (this.userData) {
      return new Date(this.userData['.expires']) < new Date(new Date().toISOString());
    };
    return false;
  }

  public logout(): void {
    localStorage.setItem('directUrl', window.location.href);
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userPermissions');
    this.ngxPermissionsService.removePermission('user');
    window.location.href = `${this.authUrl}&redirectUrl=${window.location.origin}/auth&logout=true`;
  }

  public silentTokenRefresh(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUserInfo().pipe(catchError((error) => this.handleError(error))).subscribe((value) => {
        if (!!value.refresh_token) {
          this.authenticatedUser.next(value);
          resolve(true);
          return;
        }
        this.userData = '';
        reject();
      })
    });
  }

  public tokenExpirationObservable() {
    this.authenticatedUser.subscribe(() => {
      const expirationTime = this.authenticatedUser.getValue()['.expires'];
      const dateForUpdate = new Date(expirationTime).setSeconds(new Date(expirationTime).getSeconds() - 30);
      const updateTime = dateForUpdate - <any>new Date();
      let getUserInfo$ = new Subscription();
      setTimeout(() => {
        getUserInfo$ = this.getUserInfo().pipe(
          first(),
          retry(1),
          catchError((error) => this.handleError(error))
        ).subscribe((value => {
          this.authenticatedUser.next(value);
        }));
      }, updateTime);
    });
  }

  public handleError(error: any) {
    window.location.href = this.authUrl;
    return of(error)
  }

  private checkLocalStorage() {
    if (this.userData) {
      this.authenticatedUser.next(this.userData);
    };
    if (this.userPermissionsData) {
      this.ngxPermissionsService.loadPermissions(this.userPermissionsData);
    };
    this.authenticatedUser.subscribe((value) => {
      if (value?.access_token !== this.userData?.access_token) {
        this.userData = value;
      }
    });
  }


  public getUserInfo(accountHash?: string, refreshToken?: string) {
    accountHash = accountHash ?? this.userData.accountHash;
    refreshToken = refreshToken ?? this.userData.refresh_token;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const formData: any = new URLSearchParams();
    formData.set("grant_type", "refresh_token");
    formData.set("refresh_token", refreshToken);
    formData.set("accountHash", accountHash);
    formData.set("client_id", "touchPoint");

    return this.http.post(`${environment.authApi}token`, formData, { headers: headers }).pipe(
      map((res) => {
        this.userData = res;

        return res;
      })
    );
  }

  public getPermission(): Observable<userWithPermission> {
    const url = `${environment.apiUrl}User`;

    return this.http.get<userWithPermission>(url).pipe(
      map((data) => {
        this.ngxPermissionsService.loadPermissions(data.permissions);
        localStorage.setItem('userPermissions', JSON.stringify(data.permissions));

        return data;
      })
    );
  }

}
