import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { fontFamilyOption, fontWeight } from 'projects/builder-app/src/app/core/constants/constants';
import { BuilderService } from '../../../builder/services/builder.service';
import { MediaService } from 'projects/builder-app/src/app/shared/services/media.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'projects/builder-app/src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-global-variables',
  templateUrl: './global-variables.component.html',
  styleUrls: ['./global-variables.component.scss'],
})
export class GlobalVariablesComponent implements OnInit {
  public heading = [1, 2, 3, 4, 5, 6]

  public fontWeight = fontWeight;

  public formGroup!: FormGroup;

  public isLoading = false;

  public fontFamily = fontFamilyOption;

  public globalVariables: any;

  public get getValidityState() {
    return this.formGroup?.invalid || this.formGroup?.pristine
  }

  constructor(
    private formBuilder: FormBuilder,
    private builderService: BuilderService,
    private mediaService: MediaService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.generateForm();
    this.patchFormValues();
  }

  private openDialog() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        onDecline: () => { },
        onConfirm: () => {
          this.formGroup.patchValue(this.globalVariables);
          this.formGroup.markAsPristine();
        }
      },
      disableClose: true
    });
  }

  public saveFormValues() {
    this.globalVariables = this.formGroup.getRawValue();
    this.isLoading = true;
    this.builderService.saveGlobalVariables(this.globalVariables.instanceId, JSON.stringify(this.globalVariables)).subscribe(() => {
      this.isLoading = false;
    });
    this.formGroup?.markAsUntouched();
    this.formGroup?.markAsPristine();
  }

  public cancel() {
    if (this.formGroup.dirty || !this.formGroup.pristine) {
      this.openDialog();
      return;
    }
  }

  public getNamedFormGroup(name: string) {
    return this.formGroup.get(name);
  }

  public resetForm() {
    const { instanceId } = this.formGroup.getRawValue();
    this.formGroup.reset();
    this.formGroup.get('instanceId')?.setValue(instanceId);
    this.formGroup.markAsDirty();
  }

  private patchFormValues() {
    this.isLoading = true;
    this.builderService.getGlobalVariablesInstance().subscribe(result => {
      const globalVariablesObject = JSON.parse(result.jsonContent);
      this.formGroup.get('instanceId')?.setValue(result.id);
      this.mediaService.mediaInstanceId = this.formGroup.get('instanceId')?.value;
      this.formGroup.patchValue(globalVariablesObject);
      this.globalVariables = globalVariablesObject;
      this.isLoading = false;
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.cd.detectChanges();
    })
  }

  private generateForm() {
    this.formGroup = this.formBuilder.group({
      instanceId: new FormControl(null),
      backgroundPrimary: this.formBuilder.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      backgroundSecondary: this.formBuilder.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      borders: this.formBuilder.group({
        color: new FormControl(null),
        radius: new FormControl(null),
        width: new FormControl(null),
      }),
      textSettings: this.formBuilder.group({
        fontFamily: new FormControl(null),
        heading1: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        heading2: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        heading3: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        heading4: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        heading5: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        heading6: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        mainText: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
        helpText: this.formBuilder.group({
          fontWeight: new FormControl(null),
          fontSize: new FormControl(null)
        }),
      }),
      buttonsColor: this.formBuilder.group({
        primaryColor: new FormControl(null),
        secondaryColor: new FormControl(null),
      }),
      statusColor: this.formBuilder.group({
        info: new FormControl(null),
        warning: new FormControl(null),
        success: new FormControl(null),
        error: new FormControl(null),
      }),
      loader: this.formBuilder.group({
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      scrollSettings: this.formBuilder.group({
        color: new FormControl(null),
        radius: new FormControl(null),
        width: new FormControl(null),
      }),
      cssOverride: new FormControl('')
    });
  }
}
