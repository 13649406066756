import { NgModule } from '@angular/core';
import { GlobalVariablesComponent } from './components/global-variables-component/global-variables.component';
import { GlobalVariablesRoutingModule } from './global-variables.routing';
import { CoreModule } from '../../core/core.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    GlobalVariablesRoutingModule,
    CoreModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatColorPickerModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    FormsModule,
  ],
  declarations: [GlobalVariablesComponent],
  providers: [
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
   ],
})
export class GlobalVariablesModule { }
