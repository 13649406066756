import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SkipSelf, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {

  private _value: string = "";

  public childForm: any

  private pickerInputField: any;

  public pickerOpened = false;

  public colorPickerValue$ = new BehaviorSubject(this.colorValue);

  public keyUpEvent$ = new Subscription();

  @ViewChild('colorPickerInput')
  public get input() {
    return this.pickerInputField;
  }

  public set input(v) {
    this.pickerInputField = v;
  }

  public color: any

  @Input()
  public get value() {
    return this._value;
  }

  @Input()
  form!: any;

  @Input()
  public formGroupNameValue!: string

  @Input()
  public formControlNameValue!: string

  @Input()
  public position: string = 'auto'

  public set value(v: string) {
    this._value = v;
  }

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public size: any;

  public get colorValue() {
    return this.childForm?.get(this.formControlNameValue)?.value;
  }

  constructor(
    private parentFormGroup: FormGroupDirective,
    public viewContainerRef: ViewContainerRef,) { }

  public openColorPicker() {
    this.pickerOpened = true;
  }

  public getControl(): FormControl<string> {
    return this.childForm?.get(this.formControlNameValue);
  }

  public removeColorValue() {
    this.getControl().setValue('');
    this.childForm.markAsDirty();
    this.color = '';
    this.pickerOpened = false;
  }

  public applyColor() {
    this.childForm?.get(this.formControlNameValue).setValue(this.color);
    this.childForm.markAsDirty();
    this.pickerOpened = false;
  }

  public cancel() {
    this.pickerOpened = false;
    this.color = this.colorValue;
  }

  public colorChange(event: string) {
    this.color = event;
  }

  ngOnInit() {
    if (this.formGroupNameValue && !this.form) {
      this.childForm = this.parentFormGroup.control.get(this.formGroupNameValue) as FormGroup;
    } else if (this.form?.controls) {
      if (this.formGroupNameValue) {
        this.childForm = this.form.get(this.formGroupNameValue);
      } else {
        this.childForm = this.form;
      }
    } else {
      this.childForm = this.parentFormGroup.form;
    }
    this.colorPickerValue$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(v => !!v)
    )
      .subscribe(value => {
        this.getControl().setValue(value);
        this.childForm.markAsDirty();
      });
    // this.keyUpEvent$ = fromEvent(document, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     filter((e: any) => e.keyCode === 13),
    //     distinctUntilChanged(),
    //   )
    //   .subscribe(() => this.applyColor());

    this.color = this.colorValue;
  }

  ngAfterViewInit(): void {
    // this.color = this.colorValue;
  }
  ngOnDestroy(): void {
    this.colorPickerValue$.unsubscribe();
    this.keyUpEvent$.unsubscribe();
  }

}
