import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalVariablesComponent } from './components/global-variables-component/global-variables.component';

const routes: Routes = [
  { path: '', component: GlobalVariablesComponent, title: 'Global variables'},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlobalVariablesRoutingModule { }
