<div *ngIf="getControl()" class="picker-wrapper" (click)="openColorPicker()">
  <div *ngIf="colorValue" [ngStyle]="{'background-color': colorValue}" class="picker-color"></div>
  <div *ngIf="!colorValue" class="picker-color empty">
    <div class="diagonal-line"></div>
  </div>
  <mat-icon svgIcon="dropdown-arrow"></mat-icon>
</div>
    <input #colorPickerInput  
      [cpExtraTemplate]="customTemplate"
      [cpToggle]="pickerOpened" [cpPosition]='position' 
      (colorPickerChange)="colorChange($event)"
      (colorPickerClose)="pickerOpened = false" [colorPicker]="color" />
<ng-template #customTemplate>
  <div class="color-picker-controls">
    <button mat-button color="warn" (click)="removeColorValue()">Remove</button>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" class="apply-button" (click)="applyColor()">Apply</button>
  </div>
</ng-template>