import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.gruard';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { PermissionEnum } from './shared/models/permission.enum';
import { CustomPermissionsGuard } from './core/guards/permission-guard.guard';
import { EmptyComponent } from './core/components/empty/empty.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'builder', pathMatch: 'full' },
      {
        path: 'global-variables',
        loadChildren: () => import('../app/feature/global-variables/global-variables.module').then(m => m.GlobalVariablesModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full, [PermissionEnum.access, PermissionEnum.kioskBuilder]]
        }
      },
      {
        path: 'builder',
        loadChildren: () => import('../app/feature/builder/builder.module').then(m => m.BuilderModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full, [PermissionEnum.access, PermissionEnum.kioskBuilder]]
        }
      },
      {
        path: 'components',
        loadChildren: () => import('../app/feature/components-configuration/components-configuration.module').then(m => m.ComponentsConfigurationModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full, [PermissionEnum.access, PermissionEnum.kioskBuilder]]
        }
      },
      {
        path: 'media-manager',
        loadChildren: () => import('../app/feature/media-manager/media-manager.module').then(m => m.MediaManagerModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full, [PermissionEnum.access, PermissionEnum.mediaManager]]
        }
      },
      {
        path: 'advanced-settings',
        loadChildren: () => import('../app/feature/advanced-settings/advanced-settings.module').then(m => m.AdvancedSettingsModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },
      {
        path: 'logs',
        loadChildren: () => import('../app/feature/logs/logs.module').then(m => m.AdvancedSettingsModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },
      {
        path: 'tier-levels',
        loadChildren: () => import('../app/feature/tier-levels/tier-levels.module').then(m => m.TierLevelsModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full, [PermissionEnum.access, PermissionEnum.kioskBuilder]]
        }
      },
      {
        path: 'get-kiosk-url',
        loadChildren: () => import('../app/feature/get-kiosk-url/get-kiosk-url.module').then(m => m.GetKioskUrlModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },
      {
        path: 'offers-configuration-list',
        loadChildren: () => import('../app/feature/cms-offers/cms-offers.module').then(m => m.CmsOffersModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },
      {
        path: 'data-point-manager',
        loadChildren: () => import('../app/feature/data-point-manager/data-point-manager.module').then(m => m.DataPointManagerModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },

      {
        path: 'cms-drawing-configuration-list',
        loadChildren: () => import('../app/feature/cms-drawing/cms-drawing.module').then(m => m.CmsDrawingModule),
        canActivate: [AuthGuard, CustomPermissionsGuard],
        data: {
          permissions: [PermissionEnum.full]
        }
      },

      {
        path: 'empty',
        component: EmptyComponent,
        canActivate: [AuthGuard],
      },

    ]
  },
  { path: 'auth', component: AuthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
