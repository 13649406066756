<mat-toolbar>
<div class="togler-container">
  <button (click)="toggleSidebar()"  class="toggler" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <mat-icon class="portal-icon" svgIcon="portal-icon"></mat-icon>
</div>
<button  class="toggler" mat-icon-button (click)="logout()">
  <mat-icon svgIcon="logout"></mat-icon>
</button>
</mat-toolbar>