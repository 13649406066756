import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { builderElementCurrentVersion, buttonStyle, fontFamilyOption, fontWeight } from 'projects/builder-app/src/app/core/constants/constants';
import { BuilderService } from 'projects/builder-app/src/app/feature/builder/services/builder.service';
import { Subscription } from 'rxjs';
import { TemplateName } from '../../../models/template-name.enum';
import { TemplateDialogFormService } from 'projects/builder-app/src/app/feature/builder/components/templates/template-dialog/services/template-dialog-form.service';

@Component({
  selector: 'app-base-data-point-dialog',
  templateUrl: './base-data-point-dialog.component.html',
  styleUrls: ['./base-data-point-dialog.component.css']
})
export class BaseDataPointDialogComponent implements OnInit, OnDestroy {

  public dataPointValue: any[] = [];

  public fontFamily = fontFamilyOption;

  public showCustomizeBlock = false;

  public fontWeight = fontWeight;

  public formGroup: FormGroup;

  public currentFormGroupName: string = '';

  public isIconSearchOpened = false;

  public isIconInputsShown = this.isIconSearchOpened;

  public formSubscriptions: Subscription[] = [];

  public availableDatapoints = this.builderService?.availableDataPoints;

  private _showCustomizationButton = false;
  public get showCustomizationButton() {
    return this._showCustomizationButton;
  }
  public set showCustomizationButton(value) {
    this._showCustomizationButton = value;
  }

  public inputSize = ['Small', 'Medium', "Large"];

  public buttonStyle = buttonStyle;

  public get isCustomizeSectionDirty() {
    return this.formGroup.get(this.data.objectToConfig)?.get('configurations')?.dirty;
  }

  public get rawFormValue() {
    return this.formGroup?.getRawValue();
  }

  public get mainFormRawValue() {
    return this.data.formGroup?.getRawValue();
  }

  public getDialogTitle(title: string) {
    return this.showCustomizeBlock ? `Customize ${title.toLocaleLowerCase()}` : title;
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected builderService: BuilderService,
    protected templateFormService?: TemplateDialogFormService,
  ) { }

  ngOnInit() {
  }

  public getConfigurationsFormGroup() {
    return this.formGroup.get('configurations') as FormGroup;
  }

  public getNamedConfigurationFormGroup(name: string, form?: any) {
    if (form) {
      return form.controls[name];
    }
    return (this.formGroup as any)?.get('configurations')?.controls[name];
  }

  public showRemoveButton = (formGroupName: string) => !!this.data.formGroup?.get(formGroupName);

  public showRemoveButtonForFormArrayItem(formArrayName: string) {
    return !!this.data.formGroup.get(formArrayName)?.controls[this.data.index]
  }

  public closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public customizeButtonClick() {
 
    this.showCustomizeBlock = true;
  }

  public closeCustomization() {
    this.showCustomizeBlock = false;
  }

  public deleteControl(controlName: string) {
    this.data.formGroup.removeControl(controlName);
    this.closeDialog();
  }

  public resetControl(parentFormGroupName?: string | null, formGroupToReset?: string | null, form?: any) {
    if (!this.resetToDefault(form)) {
      return;
    }

    if (form) {
      form.reset();
      form.markAsDirty();
    }
    if (parentFormGroupName && formGroupToReset) {
      this.formGroup.get(parentFormGroupName)?.get(formGroupToReset)?.reset();
      this.formGroup.get(parentFormGroupName)?.markAsDirty();
    }
  }

  public clearFormValues(objectToClear: any) {
    this.clearObject(objectToClear)
  }

  private resetToDefault(form: any) {
    const canProceedForNextStep = true;
    const updateParentForm = (value: any) => {
      this.formGroup.get('configurations')?.patchValue(value);
      this.formGroup.get('configurations')?.markAsDirty(value);
    }
    switch (this.rawFormValue.templateName) {
      case TemplateName.Offers:
        updateParentForm(
          this.templateFormService?.createOffersTemplateConfiguration().getRawValue()
        )
        return !canProceedForNextStep;
      case TemplateName.OffersAndDrawings:
        updateParentForm(
          this.templateFormService?.createOffersAndDrawingsTemplateConfiguration().getRawValue()
        )
        return !canProceedForNextStep;
      case TemplateName.OffersCms:
        updateParentForm(
          this.templateFormService?.createCmsOffersTemplateConfiguration().getRawValue()
        )
        return !canProceedForNextStep;
      case TemplateName.Redemptions:
        updateParentForm(
          this.templateFormService?.createRedemptionTemplateConfiguration().getRawValue()
        )
        return !canProceedForNextStep;
        
      case TemplateName.DrawingCms:
        updateParentForm(
          this.templateFormService?.createCmsDrawingTemplateConfiguration().getRawValue()
        )
        return !canProceedForNextStep;

      default:
        return canProceedForNextStep;
    }
  }

  public hideIconSearch() {
    this.isIconSearchOpened = false;
  }

  public removeArrayItem(array: any, itemIndex: any) {
    array.splice(itemIndex, 1);
    this.closeDialog();
  }

  public removeFormArrayItem(formArrayName: string) {
    this.data.formGroup.get(formArrayName).removeAt(this.data.index);
    this.closeDialog();
  }

  private clearObject(obj: any) {
    for (const prop of Object.getOwnPropertyNames(obj)) {
      if (!obj[prop]) {
        continue;
      }
      if (typeof obj[prop] === 'object') {
        this.clearObject(obj[prop])
      } else {
        obj[prop] = null;
      }
    }
  }

  public saveFormArrayItem(formArrayName: string, callback?: any) {
    if (this.showCustomizeBlock) {
      this.closeCustomization();
      return;
    }
    if (this.data.formGroup.get(formArrayName).controls[this.data.index]) {
      this.data.formGroup.get(formArrayName).controls[this.data.index] = this.formGroup;
      this.addVersion(this.data.formGroup.get(formArrayName).controls[this.data.index]);
      this.data.formGroup.get(formArrayName).controls[this.data.index].patchValue(this.formGroup.getRawValue());
    } else {
      this.addVersion(this.formGroup);
      this.data.formGroup.get(formArrayName).push(this.formGroup);
    }
    this.data.formGroup.markAsDirty();
    this.data.formGroup.updateValueAndValidity();
    this.closeDialog();
    if (callback) {
      callback();
    }
  }

  public saveDataPoint(name: string) {
    this.data.formGroup.markAsDirty();
    if (this.showCustomizeBlock) {
      this.closeCustomization();
      return;
    }
    if (!!this.data.formGroup.get(name)) {
      this.data.formGroup.get(name).controls = this.formGroup.controls;
      this.addVersion(this.formGroup);
      this.data.formGroup.get(name).patchValue(this.formGroup.get(name)?.getRawValue() ?? this.formGroup.getRawValue());
      this.closeDialog();
      return;
    };
    this.addVersion(this.formGroup);
    this.data.formGroup.addControl(name, this.formGroup);
    this.closeDialog();
  }

  private addVersion(formGroup: FormGroup) {
    formGroup.setControl('componentVersion', new FormControl(builderElementCurrentVersion));
  }

  public findNestedFormGroup(formGroup: any, controlName: string): FormGroup | null {
    const stack = [formGroup];
    while (stack.length > 0) {
      const currentFormGroup = stack.pop();
      const control = currentFormGroup?.get(controlName);

      if (control instanceof FormGroup) {
        return control;
      }

      for (const controlKey in currentFormGroup?.controls) {
        const nestedControl = currentFormGroup.get(controlKey);
        if (nestedControl instanceof FormGroup) {
          stack.push(nestedControl);
        }
      }
    }
    return null;
  }


  public ngOnDestroy(): void {
    if (this.currentFormGroupName) {
      const form = this.formGroup.get(this.currentFormGroupName);
      // form?.markAsUntouched();
      // form?.markAsPristine();
    }
    this.formSubscriptions.forEach(s => s.unsubscribe());
  }
}

