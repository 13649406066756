import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registerIcons() {
    this.matIconRegistry.addSvgIcon(
      `portal-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/touchpoint-portal-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `logout`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/logout.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `kiosk`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/kiosk.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `account`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/account.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `cloud`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/upload-cloud.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `image`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/image.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/delete-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `media-image-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/media-image-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `tier-level-image`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/tier-level-image-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `add-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/add-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `dropdown-arrow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/dropdown-arrow.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `check-circle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svg/check-circle.svg`)
    );
    
  }
  

}
