import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthComponent } from './components/auth/auth.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EmptyComponent } from './components/empty/empty.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatRippleModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [SidebarComponent, ToolbarComponent, AuthComponent, LayoutComponent, EmptyComponent],
  exports: [ToolbarComponent, SidebarComponent, AuthComponent, LayoutComponent, EmptyComponent]
})
export class CoreModule { }
