import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { HoverDirective } from './directives/hover.directive';
import { BackgroundInputComponent } from './components/background-input/background-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconsPickerComponent } from './components/icons-picker/icons-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DataPointActionButtonsComponent } from './components/data-point-action-buttons/data-point-action-buttons.component';
import { FourInputsComponent } from './components/four-inputs/four-inputs.component';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoaderComponent } from './components/loader/loader.component';
import { UseHttpImageSourcePipe } from './pipe/use-http-img-src.pipe';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BaseDataPointDialogComponent } from './components/common-dialog-component/base-data-point-dialog/base-data-point-dialog.component';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';

import { MatDialogModule } from '@angular/material/dialog';
import { QueryBuilderModule } from 'angular2-query-builder';
import { MatRadioModule } from '@angular/material/radio';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConditionalUppercasePipe } from './pipe/conditional-uppercase.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
@NgModule({
  imports: [
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatCardModule,
    QueryBuilderModule,
    ColorPickerModule,
    MatBadgeModule,
    MatTableModule,
    MatChipsModule
  ],
  declarations: [
    FileDragNDropDirective,
    HoverDirective,
    BackgroundInputComponent,
    ColorPickerComponent,
    IconsPickerComponent,
    DataPointActionButtonsComponent,
    FourInputsComponent,
    LoaderComponent,
    UseHttpImageSourcePipe,
    ControlPanelComponent,
    BaseDataPointDialogComponent,
    QueryBuilderComponent,
    TreeViewComponent,
    ConfirmationDialogComponent,
    ConditionalUppercasePipe,
  ],
  exports: [
    FileDragNDropDirective,
    HoverDirective,
    BackgroundInputComponent,
    ColorPickerComponent,
    IconsPickerComponent,
    DataPointActionButtonsComponent,
    ControlPanelComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    FourInputsComponent,
    LoaderComponent,
    UseHttpImageSourcePipe,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    BaseDataPointDialogComponent,
    MatDialogModule,
    MatCardModule,
    QueryBuilderComponent,
    QueryBuilderModule,
    TreeViewComponent,
    ColorPickerModule,
    ConfirmationDialogComponent,
    ConditionalUppercasePipe,
    NgxPermissionsModule,
    MatBadgeModule,
    MatChipsModule,
    CommonModule
  ]
})
export class SharedModule { }
