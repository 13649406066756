import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-point-action-buttons',
  templateUrl: './data-point-action-buttons.component.html',
  styleUrls: ['./data-point-action-buttons.component.scss']
})
export class DataPointActionButtonsComponent {

  @Input()
  public showClearButton = false;

  @Input()
  public removeButtonVisibilityCondition: any;

  @Input()
  public removeButtonDisableState: boolean | undefined = false;

  @Input()
  public showCustomizeButton: boolean = true;

  @Input()
  public showEditButton: boolean = false;

  @Input()
  public isCustomizeSectionDirty: boolean = false;

  @Output()
  onCloseAction: EventEmitter<any> = new EventEmitter();

  @Output()
  onRemoveClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onCloseCustomization: EventEmitter<any> = new EventEmitter();

  @Output()
  public onCustomizeClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onClearClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onEditClick: EventEmitter<any> = new EventEmitter();



  constructor() {
  }

  public changeClearButtonState(): void {
    if (this.showClearButton && !this.showCustomizeButton) {
      this.onCloseCustomization.emit(null)
      return;
    }
    this.showClearButton = !this.showClearButton;
  }

}
