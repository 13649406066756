import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHover]'
})
export class HoverDirective {


  @HostBinding('style.border') private borderStyle = '1px solid';
  @HostBinding('style.border-color') private borderColor = 'none';
  @HostBinding('style.border-radius') private borderRadius = '4px';
  @HostBinding('style.cursor') private cursor = 'pointer';

  @HostListener('mouseenter') onMouseEnter() {
    this.borderStyle = "1px solid";
    this.borderColor = "#D5D5D5";

  }
  @HostListener('mouseleave') onMouseLeave() {
    this.borderColor = 'transparent';
    this.borderStyle = '1px solid';
  }

  constructor() { 
    this.borderColor = 'transparent';
    this.borderStyle = '1px solid';
  }

}
