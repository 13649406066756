import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { switchMap, take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  constructor(private readonly authService: AuthService, private readonly router: Router) {
  }

  ngOnInit(): void {
    const searchUrl = window.location.search;
    const serachParams = new URLSearchParams(searchUrl);
    const refreshToken = serachParams.get('refreshToken');
    const accountHash = serachParams.get('accountHash');
    const directUrl = localStorage.getItem("directUrl");

    if (refreshToken && accountHash) {
      this.authService.userData = {
        refresh_token: refreshToken,
        accountHash
      };

      this.authService.getUserInfo().pipe(
        switchMap(() => {
          return this.authService.getPermission();
        }),
        take(1)
      ).subscribe((res) => {
        if (directUrl) {
          window.location.href = directUrl;
        } else {
          this.router.navigate(['../']);
        }
      }, () => {
        this.authService.goToLogin(directUrl?? window.location.href);
      });
    } else {
      this.authService.goToLogin(directUrl?? window.location.href);
    }
  }

}
