import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private fb: FormBuilder) { }

  public generateSuffixAndPrefixFields() {
    return {
      suffix: new FormControl(''),
      prefix: new FormControl('')
    }
  }

  public generateTextInputsConfig() {
    return this.fb.group({
      fontFamily: new FormControl(null),
      fontColor: new FormControl(null),
      fontWeight: new FormControl(null),
      fontSize: new FormControl(null),
      isUpperCaseEnabled: new FormControl(null),
    });
  }

  public generateDropDownConfig() {
    return this.fb.group({
      fontFamily: new FormControl(null),
      fontColor: new FormControl(null),
      fontWeight: new FormControl(null),
      fontSize: new FormControl(null),
      color: new FormControl(null),
      border: this.borderFormGroup(),
      background: this.backgroundFormGroup(),
      isUpperCaseEnabled: new FormControl(null),
    });
  }

  public backgroundFormGroup() {
    return this.fb.group({
      color: new FormControl(null),
      imageLink: new FormControl(null),
      imageName: new FormControl(null),
    });
  }

  public shadowFormGroup() {
    return this.fb.group({
      color: new FormControl(null),
      xPosition: new FormControl(null),
      yPosition: new FormControl(null),
      blurPosition: new FormControl(null),
    });
  }

  public borderFormGroup() {
    return this.fb.group({
      borderColor: new FormControl(null),
      borderRadius: new FormControl(null),
      borderWidth: new FormControl(null),
    });
  }

  public buttonFormGroup(hasHiddenParam?: boolean): FormGroup {
    const group = this.fb.group({
      text: this.generateTextInputsConfig(),
      border: this.borderFormGroup(),
      background: this.backgroundFormGroup(),
    });

    if (hasHiddenParam) {
      (group as FormGroup).addControl('isHidden', new FormControl(null));
    }

    return group;
  }

  public generateTwoCheckboxFormGroup() {
    return this.fb.group({
      isVisible: new FormControl(null),
      isEditable: new FormControl(null),
    });
  }

  public generateFormFromJson(jsonObj: any, formGroup: FormGroup) {
    Object.keys(jsonObj).forEach((key) => {
      if (jsonObj[key] instanceof Array) {
        const formArray = this.fb.array([]);
        jsonObj[key].forEach((item: any) => {
          if (item instanceof Object) {
            const nestedFormGroup = this.fb.group({});
            this.generateFormFromJson(item, nestedFormGroup);
            formArray.push(nestedFormGroup as any);
          } else {
            formArray.push(new FormControl(item));
          }
        });
        formGroup.addControl(key, formArray);
      } else if (jsonObj[key] instanceof Object) {
        const nestedFormGroup = this.fb.group({});
        this.generateFormFromJson(jsonObj[key], nestedFormGroup);
        formGroup.addControl(key, nestedFormGroup);
      } else {
        formGroup.addControl(key, new FormControl(jsonObj[key]));
      }
    });
  }
}
