import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor(public sidebarService: SidebarService, private readonly authService: AuthService) { }

  ngOnInit() {
  }

  public toggleSidebar() {
    this.sidebarService.isSidebarToggled$.next(!this.sidebarService.isSidebarToggled$.getValue());
  }

  public logout(): void {
    this.authService.logout();
  }

}
