<div class="tree-wrapper">
  <ng-template #nodeTemplate let-node>
    <div [ngClass]="{'connector': node.rules.length}" *ngIf="node.type === 'condition'"
      [ngStyle]="{'margin-left': !!node.parentId ? '16px' : '0'}">
      <div class="button-group-wrapper">
        <div class="condtion-group-wrapper">
          <div class="condition-group">
            <button
              [ngStyle]="{'background': node.condition === 'and' ? 'orange' :'','color':node.condition === 'and' ? 'white' : ''}"
              mat-button (click)="changeRuleCondition(node,'and')">AND</button>
            <button
              [ngStyle]="{'background': node.condition === 'or' ? 'orange' :'','color':node.condition === 'or' ? 'white' : ''}"
              mat-button (click)="changeRuleCondition(node,'or')">OR</button>
          </div>
          <div class="add-rules-group">
            <button mat-stroked-button (click)="addRule(node)" color="primary">+ ADD EXPRESSION</button>
            <button mat-stroked-button (click)="addCondition(node)" color="primary">+ ADD GROUP</button>
          </div>
        </div>
        <button mat-icon-button (click)="deleteNode(node)"><mat-icon>close</mat-icon></button>
      </div>
      <div *ngFor=" let rule of node.rules">
        <ng-container *ngTemplateOutlet="nodeTemplate; context: { $implicit: rule }"></ng-container>
      </div>
    </div>
    <div *ngIf="node.type === 'rule'" class="rule" [ngStyle]="{'margin-left': !!node.parentId ? '16px': '0'}">
      <mat-form-field appearance="outline" class="simple-input">
        <mat-label>Entity</mat-label>
        <mat-select [(ngModel)]="node.field" (ngModelChange)="emitFilterChanges(node)">
          <mat-option *ngFor="let type of entityType" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="simple-input">
        <mat-label>Operator</mat-label>
        <mat-select [(ngModel)]="node.operator" (ngModelChange)="emitFilterChanges()">
          <mat-option *ngFor="let opt of getOptions(node.field)" [value]="opt">
            {{ opt }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="extended-input">
        <mat-label>Meaning</mat-label>
        <input [disabled]="shouldBlockValueInput(node)" [id]="node.id" [(ngModel)]="node.value" matInput type="text" (ngModelChange)="emitFilterInputChanges(node.id)">
      </mat-form-field>
      <button mat-icon-button (click)="deleteNode(node)"><mat-icon>close</mat-icon></button>
    </div>
  </ng-template>
  <ng-container *ngTemplateOutlet="nodeTemplate; context: { $implicit: rootNode }"></ng-container>
</div>
