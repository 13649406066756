import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataPointListItem } from '../models/data-point-list-item';
import { DataPoint } from '../models/data-point';
import { availableCms } from '../../../core/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DataPointService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  public getDataPointList(): Observable<DataPointListItem[]> {
    return this.http.get(`${this.apiUrl}DataPoint`) as Observable<any[]>;
  };

  public getDataPointDetail(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}DataPoint/${id}`) as Observable<any>;
  };

  public updateDataPointDetail(dataPoint: DataPoint): Observable<DataPoint> {
    return this.http.put(`${this.apiUrl}DataPoint/${dataPoint.id}`, dataPoint) as Observable<DataPoint>;
  };

  public createDataPointDetail(dataPoint: DataPoint): Observable<any> {
    return this.http.post(`${this.apiUrl}DataPoint`, dataPoint) as Observable<DataPoint>;
  };

  public deleteDataPointDetail(dataPoint: DataPoint) {
    return this.http.delete(`${this.apiUrl}DataPoint/${dataPoint.id}`);
  };

  public getCmsLabel(value:string) {
    return availableCms.find(cms => cms.value === value)?.label;
  }

  public getDataPointsConfiguration(): Observable<DataPoint[]> {
    return this.http.get(`${this.apiUrl}/DataPoint/all`) as Observable<DataPoint[]>;
  };

}
