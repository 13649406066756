export const GIFT_CARD_CONFIRM_HTML = `
<div class="ofc-confirmation-container">
    <h2>Confirm purchase</h2>
    <p><b><span class="class="important-text">Important:</span> This purchase cannot be undone.</b></p>
    <p>Once confirmed, a unique link will be sent to your email on file: <span class="email">{{email}}</span></p>
    <p>Use the link to access the gift card catalog and select your eCode gift card.</p>
    <p class="underline-text">To update your email address, please visit the Player’s Club before making this purchase.</p>
    <p>Proceed with purchase?</p>
</div>
`;

export const GIFT_CARD_CONFIRM_CSS = `
.ofc-confirmation-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.ofc-confirmation-container .important-text {
    color: #e74c3c;
    font-weight: bold;
}

.ofc-confirmation-container .email {
    font-weight: bold;
}

.ofc-confirmation-container .underline-text {
    color: #0066cc;
    text-decoration: none;
}
`;

export const GIFT_CARD_DONE_HTML = `
<div class="ofc-order-complete-container">
    <h2>Order Complete</h2>
    <p>Your confirmation number is <span class="confirmation-number">#{{confirmCode}}</span></p>
    <p>Check your email or scan the QR code to select a gift card</p>
    <div class="qr-code">
        {{confirmQrCode}}
    </div>
</div>
`;

export const GIFT_CARD_DONE_CSS = `
.ofc-order-complete-container {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.ofc-order-complete-container h2 {
    font-weight: bold;
    color: #333;
}

.ofc-order-complete-container .confirmation-number {
    font-weight: bold;
}

.ofc-order-complete-container .qr-code img {
    width: 150px;
    height: 150px;
}
`;