<div class="control-panel-main">
  <span class="title">{{routeTitle}}</span>
  <div class="controls">
    <button *ngIf="showCustomizeButton" class="customize-button" mat-stroked-button color="primary"
      (click)="onCustomizeClick.emit(null)" [disabled]="customizeButtonDisableState">CUSTOMIZE</button>
    <button class="save-button" mat-raised-button color="primary" (click)="onSaveButtonClick()"
      [disabled]="disableState">SAVE</button>
    <button mat-button (click)="onCancelButtonClick()">CANCEL</button>
    <button *ngIf="showResetButton" mat-raised-button color="warn" (click)="onResetClick.emit()">RESET</button>

  </div>
</div>