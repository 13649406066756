import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from 'projects/builder-app/src/app/shared/services/form.service';
import { VOUCHER_HTML, VOUCHER_TYPES } from '../../../../constants/voucher';
import { AuthService } from 'projects/builder-app/src/app/core/services/auth.service';
import { REDEMPTION_VOUCHER_HTML } from '../../../../constants/redemption-voucher';
import {
  redemptionInformationTemplate,
  reemtpionInformationCss,
} from 'projects/builder-app/src/app/core/constants/constants';
import { CMS_VOUCHER_HTML } from '../../../../constants/cms-offer-voucher';
import { GIFT_CARD_CONFIRM_HTML, GIFT_CARD_CONFIRM_CSS, GIFT_CARD_DONE_CSS, GIFT_CARD_DONE_HTML } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TemplateDialogFormService {
  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private authService: AuthService
  ) { }

  public createProfileForm() {
    return this.fb.group({
      icon: this.fb.group({
        iconName: new FormControl(''),
        iconColor: new FormControl(null),
        iconSize: new FormControl(null),
      }),
      name: this.formService.generateTwoCheckboxFormGroup(),
      nickName: this.formService.generateTwoCheckboxFormGroup(),
      primaryAddress: this.formService.generateTwoCheckboxFormGroup(),
      preferredAddress: this.formService.generateTwoCheckboxFormGroup(),
      email: this.fb.group({
        isVisible: new FormControl(null),
        isEditable: new FormControl(null),
        isNeedConfirmation: new FormControl(null),
        regex: new FormControl('/^w+@[a-zA-Z_]+.[a-zA-Z]+$/'.toString()),
      }),
      phoneNumber: this.fb.group({
        isVisible: new FormControl(null),
        isEditable: new FormControl(null),
        isNeedConfirmation: new FormControl(null),
        regex: new FormControl(
          '/(?:(?:^|D)(([2-9])(?:d(?!\\x01)d|(?!\\x01)dd))s*[2-9]d{2}-d{4})/'.toString()
        ),
      }),
      pin: this.formService.generateTwoCheckboxFormGroup(),
      emailConfirmationText: new FormControl(
        'Are you sure you want to change your email address?'
      ),
      phoneConfirmationText: new FormControl(
        'Are you sure you want to change your phone number?'
      ),
      emailOptOut: new FormControl(null),
      phoneOptOut: new FormControl(null),
      addressOptOut: new FormControl(null),
    });
  }

  public createWinLossForm() {
    return this.fb.group({
      title: this.fb.group({
        isVisible: new FormControl(true),
      }),
      currentYear: this.fb.group({
        isVisible: new FormControl(true),
      }),
      initialText: this.fb.group({
        titleText: new FormControl({
          value: 'Win/Loss Statement',
          disabled: false,
        }),
        textBefore: new FormControl(
          'Please Select the Year of Your Win/Loss Statement bellow'
        ),
        textAfter: new FormControl(
          'Your Win/Loss statement has been sent to the email on file'
        ),
        errorMessageAfterSubmit: new FormControl(
          'Oops! There was a problem processing your request. Please contact our help desk for assistance'
        ),
      }),
    });
  }

  public createRedemptionForm() {
    return this.fb.group({
      sourceBucket: new FormControl(null, Validators.required),
      sourceBucketTitle: new FormControl(null, Validators.required),
      conversionType: new FormControl(null, Validators.required),
      conversionTypeTitle: new FormControl(null, Validators.required),
      conversionRate: new FormControl('all'),
      rateFrom: new FormControl(null),
      rateTo: new FormControl(null),
      limits: new FormControl(0),
      description: this.fb.group({
        showHtmlBoxBorder: new FormControl(true),
        html: new FormControl(redemptionInformationTemplate),
        css: new FormControl(reemtpionInformationCss),
      }),
      explanation: this.fb.group({
        text: new FormControl(''),
        helpText: new FormControl(''),
      }),
      tiredConversionRate: this.fb.array([]),
      buttonsOption: new FormControl('tenKeyEntry'),
      preSets: this.fb.array([]),
      isPrintVoucherRequired: new FormControl(false),
    });
  }

  public createPdfForm() {
    return this.fb.group({
      url: new FormControl(null),
      fileUrl: new FormControl(null),
      fileName: new FormControl(null),
    });
  }

  public createGiftCardForm() {
    const form: FormGroup = this.createRedemptionForm();
    
    form.addControl('qrUrl', new FormControl(''));
    form.addControl('qrCodeTitle', new FormControl('Want to view the catalog first?'));
    form.addControl('qrCodeText', new FormControl('Scan the QR code to view the gift card catalog.'));
    form.addControl('beginYourPurchase', new FormControl('Begin your purchase'));
    form.addControl('enterAmount', new FormControl('Enter Gift Card amount:'));
    form.addControl('minValue', new FormControl(5000));
    form.addControl('maxValue', new FormControl(2000000));
    form.addControl('ivalidMinAmountTitle', new FormControl('Invalid amount'));
    form.addControl('ivalidMinAmountText', new FormControl('The point value you are trying to redeem is below the minimum of 5,000 points. Please try again.'));
    form.addControl('ivalidMaxAmountTitle', new FormControl('Invalid amount'));
    form.addControl('ivalidMaxAmountText', new FormControl('The point value you are trying to redeem exceeds the max limit of 2,000,000 points. Please try again.'));
    form.addControl('confirmTextHtml', new FormControl(GIFT_CARD_CONFIRM_HTML));
    form.addControl('confirmTextCss', new FormControl(GIFT_CARD_CONFIRM_CSS));
    form.addControl('doneTextHtml', new FormControl(GIFT_CARD_DONE_HTML));
    form.addControl('doneTextCss', new FormControl(GIFT_CARD_DONE_CSS));
    form.addControl('missingEmailTitle', new FormControl('Missing email'));
    form.addControl('missingEmailText', new FormControl('You do not have an email on file. The transaction cannot be completed. Please visit the Player’s Club to update your account email address.'));

    return form;
  }

  public createMainConfigurationForm() {
    const fs = this.formService;
    return this.fb.group({
      main: this.fb.group({
        title: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
      }),
    });
  }

  public createWebPageForm() {
    return this.fb.group({
      url: new FormControl('', Validators.required),
    });
  }

  public createDrawingPageForm() {
    return this.fb.group({
      displaySettings: this.fb.group({
        showMultipliers: new FormControl(true),
        showFreeEntries: new FormControl(true),
        showDrawings: new FormControl(true),
        showTierEntries: new FormControl(true),
      }),
    });
  }

  public createOffersForm() {
    return this.fb.group(this.createDefaultOfferConfiguration());
  }

  public createOffersAndDrawingsPageForm() {
    return this.fb.group({
      ...this.createDefaultOfferConfiguration(),
      displaySettings: this.fb.group({
        showMultipliers: new FormControl(true),
        showFreeEntries: new FormControl(true),
        showDrawings: new FormControl(true),
        showTierEntries: new FormControl(true),
      }),
    });
  }

  public createOffersAndOffersCmsForm() {
    return this.fb.group({
      ...this.createDefaultOfferConfiguration(),
      fieldToMatch: new FormControl('description'),
    });
  }

  public createOffersCmsOffersAndDrawingsForm() {
    return this.fb.group({
      ...this.createDefaultOfferConfiguration(),
      fieldToMatch: new FormControl('description'),
      displaySettings: this.fb.group({
        showMultipliers: new FormControl(true),
        showFreeEntries: new FormControl(true),
        showDrawings: new FormControl(true),
        showTierEntries: new FormControl(true),
      }),
    });
  }

  public createOffersCmsOffersAndDrawingsTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noItemsText: new FormControl('There are currently no items available.'),
        noItems: this.formService.generateTextInputsConfig(),
        noOffers: this.formService.generateTextInputsConfig(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        redeemedOfferImage: this.fb.group({
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        subpages: this.offerPageButtonsFormGroup(),
        redeemedOfferDisplaySettings: this.fb.group({
          removeFromDisplay: new FormControl(false),
          isWatermarkAdded: new FormControl(false),
          isWatermarkOverOffer: new FormControl(false),
          isWatermarkOverOfferAndDetails: new FormControl(false),
          isGrayscaled: new FormControl(false),
          canOpenDetails: new FormControl(false),
        }),
      }),
      cardDrawing: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(true),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardFreeEntry: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardTierEntry: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardMultiplier: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      drawingDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        date: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
        sliderButtonActivate: this.formService.buttonFormGroup(),
        sliderButtonInactive: this.formService.buttonFormGroup(),
        sliderText: this.formService.generateTextInputsConfig(),
      }),
      freeEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      tierEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      multiplierDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardOffer: this.createCardOffer(),
      cardOfferDetails: this.createCardOfferDetails(),
      voucher: this.fb.group({
        type: new FormControl(VOUCHER_TYPES[0]),
        html: new FormControl(
          VOUCHER_HTML(this.authService.userData.clientName)
        ),
        cmsHtml: new FormControl(
          CMS_VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
      welcomePopUp: this.fb.group({
        isEnabled: new FormControl(false),
        schedule: new FormControl('0'),
        weekdaySchedule: this.fb.group({
          isEnabled: new FormControl(false),
          selectedDays: new FormControl([]),
        }),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        message: new FormControl(''),
        messageStyle: this.formService.generateTextInputsConfig(),
        buttonText: new FormControl(''),
        button: this.formService.buttonFormGroup()
      })
    });
  }

  public createOffersCmsForm() {
    return this.fb.group({
      displayType: new FormControl('Icons'),
      configuration: new FormControl('Square'),
      fieldToMatch: new FormControl('description'),
      isNeedToCloseAfterPrint: new FormControl(true),
    });
  }

  public createDrawingCmsForm() {
    return this.fb.group({
      displayType: new FormControl('Icons'),
      configuration: new FormControl('Square'),
      fieldToMatch: new FormControl('description'),
      openDetailedView: this.fb.group({
        isVisible: new FormControl(true),
      }),
    });
  }


  public createProfileTemplateConfigurations() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        text: this.formService.generateTextInputsConfig(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        optOut: this.fb.group({
          text: this.formService.generateTextInputsConfig(),
          backgroundColor: this.fb.group({
            checked: new FormControl(null),
            unchecked: new FormControl(null),
          }),
          border: this.formService.borderFormGroup(),
          checkmarkColor: new FormControl(null),
        }),
      }),
      inputs: this.fb.group({
        mainConfig: this.fb.group({
          size: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        backgroundColor: this.fb.group({
          default: new FormControl(null),
          active: new FormControl(null),
          error: new FormControl(null),
        }),
        borderColor: this.fb.group({
          default: new FormControl(null),
          active: new FormControl(null),
          error: new FormControl(null),
        }),
        title: this.formService.generateTextInputsConfig(),
        text: this.formService.generateTextInputsConfig(),
        placeholder: this.formService.generateTextInputsConfig(),
        errorText: this.formService.generateTextInputsConfig(),
        // this.fb.group({})
      }),
      buttons: this.fb.group({
        primaryButtons: this.buttonsFormGroup(),
        secondaryButtons: this.buttonsFormGroup(),
        editButton: this.buttonsFormGroup(),
        innerText: new FormControl('More'),
        editButtonIcon: this.fb.group({
          iconName: new FormControl(''),
          iconColor: new FormControl(null),
          iconSize: new FormControl(null),
        }),
      }),
      popUp: this.fb.group({
        style: new FormControl('At the bottom of the screen'),
        messageText: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        overlay: new FormControl(),
        buttonConfirm: this.formService.buttonFormGroup(),
        buttonCancel: this.formService.buttonFormGroup(),
      }),
    });
  }
  private buttonsFormGroup(): FormGroup {
    return this.fb.group({
      buttonStyle: new FormControl(null),
      text: this.formService.generateTextInputsConfig(),
      border: this.fb.group({
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
        color: new FormControl(null),
      }),
      background: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
    });
  }

  private offerPageButtonsFormGroup(): FormGroup {
    return this.fb.group({
      textSelected: this.formService.generateTextInputsConfig(),
      textUnselected: this.formService.generateTextInputsConfig(),
      border: this.fb.group({
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
        color: new FormControl(null),
      }),
      background: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
    });
  }

  private drawingPageButtonsFormGroup(): FormGroup {
    return this.fb.group({
      textSelected: this.formService.generateTextInputsConfig(),
      textUnselected: this.formService.generateTextInputsConfig(),
      border: this.fb.group({
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
        color: new FormControl(null),
      }),
      background: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
    });
  }

  public createRedemptionTemplateConfiguration() {
    const fs = this.formService;
    return this.fb.group({
      main: this.fb.group({
        title: fs.generateTextInputsConfig(),
        testTitle: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        explanation: fs.generateTextInputsConfig(),
        amountExplanation: this.fb.group({
          number: fs.generateTextInputsConfig(),
          text: fs.generateTextInputsConfig(),
        }),
      }),
      dataPoints: this.fb.group({
        titlePoints: fs.generateTextInputsConfig(),
        value: fs.generateTextInputsConfig(),
        border: fs.borderFormGroup(),
        background: fs.backgroundFormGroup(),
      }),
      preSets: this.fb.group({
        amountButton: fs.buttonFormGroup(),
        addButton: fs.buttonFormGroup(),
        disabledAddButton: fs.buttonFormGroup(),
        removeButton: fs.buttonFormGroup(),
        disabledremoveButton: fs.buttonFormGroup(),
        maxAmountButton: fs.buttonFormGroup(),
        inputAmount: this.fb.group({
          inputSettings: this.fb.group({
            borderRadius: new FormControl(),
            borderWidth: new FormControl(),
            backgroundColor: new FormControl(),
            borderColor: new FormControl(),
          }),
          title: fs.generateTextInputsConfig(),
          text: fs.generateTextInputsConfig(),
        }),
      }),
      freeEntry: this.fb.group({
        numeralButton: fs.buttonFormGroup(),
        deleteButton: fs.buttonFormGroup(),
        deleteButtonIcon: this.fb.group({
          iconName: new FormControl(''),
          iconColor: new FormControl(null),
          iconSize: new FormControl(null),
        }),
        confirmationButton: fs.buttonFormGroup(),
        inputAmount: this.fb.group({
          inputSettings: this.fb.group({
            borderRadius: new FormControl(),
            borderWidth: new FormControl(),
            backgroundColor: new FormControl(),
            borderColor: new FormControl(),
          }),
          title: fs.generateTextInputsConfig(),
          text: fs.generateTextInputsConfig(),
        }),
      }),
      buttons: this.fb.group({
        butonsConfirm: fs.buttonFormGroup(),
        buttonsCancel: fs.buttonFormGroup(),
        buttonsClearAll: fs.buttonFormGroup(),
      }),
      popUp: this.fb.group({
        style: new FormControl('At the bottom of the screen'),
        messageText: fs.generateTextInputsConfig(),
        accentMessageText: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        border: fs.borderFormGroup(),
        overlay: new FormControl(),
        buttonConfirm: fs.buttonFormGroup(),
        buttonCancel: fs.buttonFormGroup(),
      }),
      voucher: this.fb.group({
        expirationDays: new FormControl(0),
        html: new FormControl(
          REDEMPTION_VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
    });
  }

  public createWinAndLossConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        yearDropdown: this.formService.generateDropDownConfig(),
        background: this.formService.backgroundFormGroup(),
        borderColor:  this.formService.borderFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        textBefore: this.formService.generateTextInputsConfig(),
        textAfter: this.formService.generateTextInputsConfig(),
        errorMessageAfterSubmit: this.formService.generateTextInputsConfig(),

      }),
      inputs: this.fb.group({
        mainConfig: this.fb.group({
          size: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        background: this.formService.backgroundFormGroup(),
        borderColor:  this.formService.borderFormGroup(),
        titleWL: this.formService.generateTextInputsConfig(),
        textBefore: this.formService.generateTextInputsConfig(),
        textAfter: this.formService.generateTextInputsConfig(),
        errorMessageAfterSubmit: this.formService.generateTextInputsConfig(),
      }),
      buttons: this.fb.group({
        primaryButtons: this.buttonsFormGroup(),
        innerTextButton: new FormControl('Submit Year'),     
      }),
    });
  }
  
  public createGiftCardTemplateConfiguration() {
    return this.createRedemptionTemplateConfiguration();
  }

  public createDrawingTemplateConfiguration() {
    const fs = this.formService;
    return this.fb.group({
      main: this.fb.group({
        title: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        noDrawingsText: new FormControl(
          'There are currently no drawings available.'
        ),
        noDrawingTextConfig: fs.generateTextInputsConfig(),
      }),
      cardDrawing: this.fb.group({
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        border: fs.borderFormGroup(),
        shadow: fs.shadowFormGroup(),
        buttonDetail: fs.buttonFormGroup(true),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      cardFreeEntry: this.fb.group({
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        border: fs.borderFormGroup(),
        shadow: fs.shadowFormGroup(),
        buttonDetail: fs.buttonFormGroup(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      cardTierEntry: this.fb.group({
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        border: fs.borderFormGroup(),
        shadow: fs.shadowFormGroup(),
        buttonDetail: fs.buttonFormGroup(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      cardMultiplier: this.fb.group({
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        border: fs.borderFormGroup(),
        shadow: fs.shadowFormGroup(),
        buttonDetail: fs.buttonFormGroup(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      drawingDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: fs.generateTextInputsConfig(),
        date: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        imageBorder: fs.borderFormGroup(),
        detailBackground: fs.backgroundFormGroup(),
        detailBorder: fs.borderFormGroup(),
        detailEntry: fs.generateTextInputsConfig(),
        detailText: fs.generateTextInputsConfig(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
        sliderButtonActivate: fs.buttonFormGroup(),
        sliderButtonInactive: fs.buttonFormGroup(),
        sliderText: fs.generateTextInputsConfig(),
      }),
      freeEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        imageBorder: fs.borderFormGroup(),
        detailBackground: fs.backgroundFormGroup(),
        detailBorder: fs.borderFormGroup(),
        detailEntry: fs.generateTextInputsConfig(),
        detailText: fs.generateTextInputsConfig(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      tierEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        imageBorder: fs.borderFormGroup(),
        detailBackground: fs.backgroundFormGroup(),
        detailBorder: fs.borderFormGroup(),
        detailEntry: fs.generateTextInputsConfig(),
        detailText: fs.generateTextInputsConfig(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      multiplierDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: fs.generateTextInputsConfig(),
        description: fs.generateTextInputsConfig(),
        background: fs.backgroundFormGroup(),
        imageBorder: fs.borderFormGroup(),
        detailBackground: fs.backgroundFormGroup(),
        detailBorder: fs.borderFormGroup(),
        detailEntry: fs.generateTextInputsConfig(),
        detailText: fs.generateTextInputsConfig(),
        buttonActivate: fs.buttonFormGroup(),
        buttonActivated: fs.buttonFormGroup(),
      }),
      welcomePopUp: this.fb.group({
        isEnabled: new FormControl(false),
        schedule: new FormControl('0'),
        weekdaySchedule: this.fb.group({
          isEnabled: new FormControl(false),
          selectedDays: new FormControl([]),
        }),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        message: new FormControl(''),
        messageStyle: this.formService.generateTextInputsConfig(),
        buttonText: new FormControl(''),
        button: this.formService.buttonFormGroup()
      })
    });
  }

  public createCmsOffersTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noOffersText: new FormControl(
          'There are currently no offers available.'
        ),
        noOffers: this.formService.generateTextInputsConfig(),
        redeemedOfferImage: this.fb.group({
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        redeemedOfferDisplaySettings: this.fb.group({
          removeFromDisplay: new FormControl(false),
          isWatermarkAdded: new FormControl(false),
          isWatermarkOverOffer: new FormControl(false),
          isWatermarkOverOfferAndDetails: new FormControl(false),
          isGrayscaled: new FormControl(false),
          canOpenDetails: new FormControl(false),
        }),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        subpages: this.offerPageButtonsFormGroup(),
      }),
      cardOffer: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        upcomingOfferTitleColor: new FormControl(null),
        diagonalLabelButton: this.buttonsFormGroup(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        border: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        buttonOfferDetailsText: new FormControl('Offer detail'),
        buttonOfferDetails: this.buttonsFormGroup(),
      }),
      cardOfferDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        text: this.formService.generateTextInputsConfig(),
        printDescription: this.formService.generateTextInputsConfig(),
        validTillPrefix: new FormControl('Valid till:'),

        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        buttonPrintOfferText: new FormControl('Print'),
        buttonPrintOffer: this.buttonsFormGroup(),
        redeemOfferButton: this.buttonsFormGroup(),
        border: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        imageBorder: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        buttonSection: this.fb.group({
          background: this.fb.group({
            color: new FormControl(null),
            imageLink: new FormControl(null),
            imageName: new FormControl(null),
          }),
          border: this.fb.group({
            borderColor: new FormControl(null),
            borderRadius: new FormControl(null),
            borderWidth: new FormControl(null),
          }),
        }),
      }),
      voucher: this.fb.group({
        type: new FormControl(VOUCHER_TYPES[1]),
        html: new FormControl(
          CMS_VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
    });
  }

  public createCmsDrawingTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noDrawingText: new FormControl(
          'There are currently no Drawings available.'
        ),
        noDrawing: this.formService.generateTextInputsConfig(),
        
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        subpages: this.drawingPageButtonsFormGroup(),
      }),
      cardDrawing: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        upcomingDrawingTitleColor: new FormControl(null),
        diagonalLabelButton: this.buttonsFormGroup(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        balance: new FormControl(
          'Current Balance: '
        ),   
        balanceText: this.formService.generateTextInputsConfig(),
        border: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        buttonDrawingDetailsText: new FormControl('Drawing detail'),
        buttonDrawingDetails: this.buttonsFormGroup(),
      }),
      cardDrawingDetails: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        text: this.formService.generateTextInputsConfig(),
        printDescription: this.formService.generateTextInputsConfig(),
        balanceDetail: new FormControl(
          'Current Balance: '
        ),
        balanceDetailText: this.formService.generateTextInputsConfig(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        border: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        imageBorder: this.fb.group({
          borderColor: new FormControl(null),
          borderRadius: new FormControl(null),
          borderWidth: new FormControl(null),
        }),
        buttonSection: this.fb.group({
          background: this.fb.group({
            color: new FormControl(null),
            imageLink: new FormControl(null),
            imageName: new FormControl(null),
          }),
          border: this.fb.group({
            borderColor: new FormControl(null),
            borderRadius: new FormControl(null),
            borderWidth: new FormControl(null),
          }),
        }),
        closeButton: this.formService.buttonFormGroup(),
      }),
    });
  }

  public createOffersTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noOffersText: new FormControl(
          'There are currently no offers available.'
        ),
        noOffers: this.formService.generateTextInputsConfig(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        subpages: this.offerPageButtonsFormGroup(),
      }),
      cardOffer: this.createCardOffer(),
      cardOfferDetails: this.createCardOfferDetails(),
      voucher: this.fb.group({
        type: new FormControl(VOUCHER_TYPES[0]),
        html: new FormControl(
          VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
    });
  }

  public createOffersAndOffersCmsTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noOffersText: new FormControl(
          'There are currently no offers available.'
        ),
        noOffers: this.formService.generateTextInputsConfig(),
        background: this.fb.group({
          color: new FormControl(null),
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        redeemedOfferImage: this.fb.group({
          imageLink: new FormControl(null),
          imageName: new FormControl(null),
        }),
        subpages: this.offerPageButtonsFormGroup(),
        redeemedOfferDisplaySettings: this.fb.group({
          removeFromDisplay: new FormControl(false),
          isWatermarkAdded: new FormControl(false),
          isWatermarkOverOffer: new FormControl(false),
          isWatermarkOverOfferAndDetails: new FormControl(false),
          isGrayscaled: new FormControl(false),
          canOpenDetails: new FormControl(false),
        }),
      }),
      cardOffer: this.createCardOffer(),
      cardOfferDetails: this.createCardOfferDetails(),
      voucher: this.fb.group({
        type: new FormControl(VOUCHER_TYPES[0]),
        html: new FormControl(
          VOUCHER_HTML(this.authService.userData.clientName)
        ),
        cmsHtml: new FormControl(
          CMS_VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
    });
  }

  public createOffersAndDrawingsTemplateConfiguration() {
    return this.fb.group({
      main: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        noItemsText: new FormControl('There are currently no items available.'),
        noItems: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
      }),
      cardDrawing: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(true),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardFreeEntry: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardTierEntry: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardMultiplier: this.fb.group({
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        shadow: this.formService.shadowFormGroup(),
        buttonDetail: this.formService.buttonFormGroup(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      drawingDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        date: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
        sliderButtonActivate: this.formService.buttonFormGroup(),
        sliderButtonInactive: this.formService.buttonFormGroup(),
        sliderText: this.formService.generateTextInputsConfig(),
      }),
      freeEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      tierEntryDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      multiplierDetails: this.fb.group({
        closeButton: this.formService.buttonFormGroup(),
        title: this.formService.generateTextInputsConfig(),
        description: this.formService.generateTextInputsConfig(),
        background: this.formService.backgroundFormGroup(),
        imageBorder: this.formService.borderFormGroup(),
        detailBackground: this.formService.backgroundFormGroup(),
        detailBorder: this.formService.borderFormGroup(),
        detailEntry: this.formService.generateTextInputsConfig(),
        detailText: this.formService.generateTextInputsConfig(),
        buttonActivate: this.formService.buttonFormGroup(),
        buttonActivated: this.formService.buttonFormGroup(),
      }),
      cardOffer: this.createCardOffer(),
      cardOfferDetails: this.createCardOfferDetails(),
      voucher: this.fb.group({
        type: new FormControl(VOUCHER_TYPES[0]),
        html: new FormControl(
          VOUCHER_HTML(this.authService.userData.clientName)
        ),
      }),
      welcomePopUp: this.fb.group({
        isEnabled: new FormControl(false),
        schedule: new FormControl('0'),
        weekdaySchedule: this.fb.group({
          isEnabled: new FormControl(false),
          selectedDays: new FormControl([]),
        }),
        background: this.formService.backgroundFormGroup(),
        border: this.formService.borderFormGroup(),
        message: new FormControl(''),
        messageStyle: this.formService.generateTextInputsConfig(),
        buttonText: new FormControl(''),
        button: this.formService.buttonFormGroup()
      })
    });
  }

  private createCardOffer(): FormGroup {
    return this.fb.group({
      title: this.formService.generateTextInputsConfig(),
      upcomingOfferTitleColor: new FormControl(null),
      diagonalLabelButton: this.buttonsFormGroup(),

      background: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      border: this.fb.group({
        borderColor: new FormControl(null),
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
      }),
      buttonOfferDetailsText: new FormControl('Offer detail'),
      buttonOfferDetails: this.buttonsFormGroup(),
    });
  }

  private createCardOfferDetails(): FormGroup {
    return this.fb.group({
      closeButton: this.formService.buttonFormGroup(),
      title: this.formService.generateTextInputsConfig(),
      upcomingOfferTitleColor: new FormControl(null),
      text: this.formService.generateTextInputsConfig(),
      background: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      buttonPrintOfferText: new FormControl('Print'),
      buttonRedeemOfferText: new FormControl('Redeem'),
      buttonPrintOffer: this.buttonsFormGroup(),
      buttonPrintRedeemOfferInactive: this.buttonsFormGroup(),
      buttonPrintedOfferText: new FormControl('Printed'),
      buttonRedeemedOfferText: new FormControl('Redeem'),
      printedRedeemButtonStyles: this.formService.generateTextInputsConfig(),
      validTillPrefix: new FormControl('Valid till:'),
      qrCodeBackground: this.fb.group({
        color: new FormControl(null),
        imageLink: new FormControl(null),
        imageName: new FormControl(null),
      }),
      border: this.fb.group({
        borderColor: new FormControl(null),
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
      }),
      qrCodeText: this.formService.generateTextInputsConfig(),
      imageBorder: this.fb.group({
        borderColor: new FormControl(null),
        borderRadius: new FormControl(null),
        borderWidth: new FormControl(null),
      }),
    });
  }

  private createDefaultOfferConfiguration() {
    return {
      displayType: new FormControl('Icons'),
      configuration: new FormControl('Square'),
      lobbyToken: new FormControl(null, Validators.required),
      filterByCriteria: new FormControl(null),
      filterByTreshold: new FormControl(null),
      sorting: new FormControl('0'),
      isNeedToCloseAfterPrint: new FormControl(true),
    }
  }
}
