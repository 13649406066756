import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {

  @Output()
  public onSaveClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onCancelClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onCustomizeClick: EventEmitter<any> = new EventEmitter();

  
  @Output()
  public onResetClick: EventEmitter<any> = new EventEmitter();

  @Input()
  disableState: boolean = false;

  @Input()
  public showCustomizeButton: boolean = false;

  @Input()
  public customizeButtonDisableState: boolean = false;

  @Input()
  public showResetButton = false;

  constructor(private activatedRoute: ActivatedRoute) { }

  public routeTitle: string = this.activatedRoute.snapshot.title ?? '';

  ngOnInit() {
  }

  onCancelButtonClick() {
    this.onCancelClick.emit();
  }

  public onSaveButtonClick() {
    this.onSaveClick.emit();
  }

}
