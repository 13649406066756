import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PermissionEnum } from '../../shared/models/permission.enum';

@Injectable({
    providedIn: 'root'
})
export class CustomPermissionsGuard implements CanActivate {
    constructor(private permissionsService: NgxPermissionsService, private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        const requiredPermissions = route.data.permissions;
        const userPermissions = this.authService.userPermissionsData;
        const routeUrl = route.routeConfig?.path;
        const hasRequiredPermission = requiredPermissions.some((perm: string | string[]) => {

            if (Array.isArray(perm)) {
                return perm.every((p: string) => userPermissions?.includes(p));
            } else {
                return userPermissions?.includes(perm);
            }
        });

        switch (true) {
            case routeUrl === 'builder' && !hasRequiredPermission:
                this.router.navigate(['media-manager']);
                break;
            case routeUrl === 'media-manager' && !hasRequiredPermission:
                this.router.navigate(['empty']);
                break;
        }

        return hasRequiredPermission;
    }
}