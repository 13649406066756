<form [formGroup]="form">
  <div [formGroupName]="formGroupNameValue" class="four-input-group">
    <div class="inputs-group-row">
      <div class="input-group">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="form-field">
          <mat-label>Font family</mat-label>
          <mat-select formControlName="fontFamily" class="select">
            <mat-option *ngFor="let item of selectFieldOptions" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-color-picker [position]="colorPickerPosition" formControlNameValue="fontColor" [formGroupNameValue]="formGroupNameValue"></app-color-picker>
      </div>
    </div>
    <div class="second-input-row-without-title">
      <div class="input-group">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Font weight</mat-label>
          <mat-select formControlName="fontWeight" class="select form-field">
            <mat-option *ngFor="let item of fontWeight" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="form-field">
          <mat-label>Font size</mat-label>
          <input formControlName="fontSize" matInput type="number">
        </mat-form-field>
        <mat-checkbox *ngIf="isUpperCaseShown" color="primary" formControlName="isUpperCaseEnabled">Uppercase</mat-checkbox>
      </div>
    </div>
  </div>
</form>