<div class="sidebar-main-container" [ngClass]="{ collapsed: sidebarService.isSidebarToggled$ | async }">
  <div *ngIf="!(sidebarService.isSidebarToggled$ | async); else collapsed" class="navigation">
    <div class="account-container">
      <div class="name-container">
        <mat-icon svgIcon="account"></mat-icon>
        <div class="name-container-data">
          <span>{{ userData.displayName }}</span>
          <span class="small">{{ userData.clientName }}</span>
        </div>
      </div>
    </div>
    <mat-accordion>
      <ng-container *ngFor="let item of navigationElements">
        <div *ngIf="!item.sections && item.url && hasAnyPermission(item.permissions)" class="single-route-link" [routerLink]="item.url">
          <div class="navigation-element">
            <mat-icon class="navigation-icon"
              [ngClass]="{'selected-group-icon': isGroupSelected(item)}">{{item.icon}}</mat-icon>
            {{item.title}}
          </div>
        </div>
        <mat-expansion-panel [expanded]="isGroupSelected(item.sections)" *ngIf="hasAnyPermission(item.permissions) && item.sections ">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="navigation-icon"
                [ngClass]="{'selected-group-icon': isGroupSelected(item.sections)}">{{item.icon}}</mat-icon>
              <!-- <mat-icon class="navigation-icon" svgIcon="kiosk"></mat-icon> -->
              {{item.title}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of item.sections">
            <ng-container *ngIf="hasAnyPermission(section.permissions)">
              <span *ngIf="section.title" class=" group-title">{{section.title}}</span>
              <ng-container *ngFor="let route of section.routes">
                <div *ngIf="hasAnyPermission(route.permissions)" matRipple class="navigation-element"
                  routerLinkActive="selected" [routerLink]="[route.url]">
                  <span>{{ route.name }}</span>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>

<ng-template #collapsed>
  <div class="collapsed-navigation">
    <button matRipple class="account-button" mat-icon-button>
      <mat-icon svgIcon="account"></mat-icon>
    </button>
    <ng-container *ngFor="let item of navigationElements">
      <button class="account-button" mat-icon-button matRipple *ngIf="hasAnyPermission(item.permissions)"
        [ngClass]="{'selected-group-icon': isGroupSelected(item.sections)}">
        <mat-icon>{{item.icon}}</mat-icon>
      </button>
    </ng-container>
  </div>
</ng-template>
