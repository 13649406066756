export const REDEMPTION_VOUCHER_HTML = (userData: string) => {
    return `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        body {
            font-family: sans-serif;
        }

        h1 {
            font-size: 32px;
            text-align: center;
            margin: 0 0 5px;
        }

        h2 {
            font-size: 24px;
            text-align: center;
            margin: 0 0 15px;
        }

        h3 {
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            margin: 0;
        }

        p {
            font-size: 18px;
            margin: 0;
        }

        h4 {
            font-size: 22px;
            text-align: center;
            margin: 0 0 5px;
        }

        h5 {
            font-size: 22px;
            font-weight: 400;
            text-align: center;
            margin: 0;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .title {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .center {
            text-align: center;
        }
    </style>
</head>

<body>
    <h1>${userData}</h1>
    <h2>Voucher</h2>
    <h3>Voucher #: <b>{{voucherId}}</b></h3>
    <br />
    <br />
    <p>Print Date: {{nowDate}}</p>
    <p>Patron's name: {{patronName}}</p>
    <br />
    <p class="title">Promotion name:{{promotionName}}</p>
    <p>Redeemed: {{convertedPoints}}</p>
    <br />
    <p>Expires: {{expirationDate}}</p>
    <br />
    <br />
    <p>Employee Name: {{employeeName}}</p>
    <br />
    <p>Employee Signature: .........</p>
    <br />
    <br />
    <p>Guest Name: {{patronName}}</p>
    <br />
    <p>Guest Signature: .........</p>
    <br />

    <h4>Terms and Conditions</h4>
    <p class="center">Terms and Conditions</p>
    <br />
    <br />
    <br />
</body>

</html>
    `;
};

