import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdvancedSettings } from '../models/advanced-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSettingsService {

  constructor(private http: HttpClient) { }

  public getAdvancedSettings() {
    return this.http.get(`${environment.apiUrl}AccountMetadata/settings`) as Observable<AdvancedSettings>;
  }

  public saveAdvancedSettings(body: any) {
    return this.http.put(`${environment.apiUrl}AccountMetadata/settings`, body);
  }

  public getCmsValue() {
    return this.http.get(`${environment.apiUrl}AccountMetadata/settings/cms`) as Observable<{ type: string, ucVersion: string }>;
  }

}
