import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, defer, of, throwError, filter } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private snackBar: MatSnackBar, private zone: NgZone,) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return defer(() => this.authService.silentTokenRefresh()).pipe(
            switchMap(() => {
              request = request.clone({
                headers: request.headers.set("Authorization", `Bearer ${this.authService.userData.access_token}`)
              });
              return next.handle(request);
            }))
        } else if (error.status === 400) {
          error.error.errors.filter((err: string) => !!err.length).forEach((err: string) => {
            this.openSnackBar(err);
          });
          return throwError(error.message || '');
        } else {
          this.openSnackBar("Http failure response");
          return throwError(error.message || '');
        }
      }))
  }

  openSnackBar(msg: string) {
    this.snackBar.open(msg, 'Cancel', { panelClass: 'error', duration: 5000, horizontalPosition: 'right', verticalPosition: 'top' });
  }
}
