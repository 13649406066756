<div class="background-input-content" [class]="uniqClass">
  <span *ngIf="title" class="control-title">{{title}}</span>
  <ng-container></ng-container>
  <ng-template [ngIf]="!showOtherControls" #name>
    <ng-container *ngTemplateOutlet="list"></ng-container>
  </ng-template>
  <ng-template #name [ngIf]="showOtherControls">
    <div [formGroup]="form" class="background-input-wrapper" *ngIf="!form?.get('imageLink')?.value">
      <app-color-picker *ngIf="showColorPicker" [formControlNameValue]="colorPropertyPath"
      [position]="colorPickerPosition"
        [form]="form"></app-color-picker>
      <span *ngIf="showColorPicker" class="or">or</span>
      <div *ngIf="showOtherControls" class="file-uploader">
        <div class="dropzone-wrapper">
          <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event,imagePropertyPath)">
            <input #input type="file" name="file" accept=".svg, .png, .jpg, .webp, .gif" id="file"
              (change)="onFileChange($event,imagePropertyPath)">
            <div class="file-upload-info">
              <mat-icon svgIcon="cloud"></mat-icon>
              <div class="upload-text">
                <span class="drop-target">Select a file or drag and drop here</span>
                <span class="file-type-info">JPG, PNG, GIF OR SVG, file size no more than 10mb</span>
              </div>
              <div class="outlined-button" (click)="input.click()">Select</div>
            </div>
            <div *ngIf="file?.inProgress && !file?.id" class="progress-bar-container">
              <div class="progress-bar" [ngStyle]="{'width': (file.progress | async) + '%'}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="form?.get('imageLink')?.value && showOtherControls" class="uploaded-file">
    <div class="uploaded-file-container">
      <img [src]="form.get('imageLink').value" [alt]="form.get('imageName').value">
      <div class="image-info-container">
        <span class="image-name">{{form.get('imageName').value}}</span>
        <span class="image-upload-status">File successfully uploaded</span>
      </div>
      <div class="icons-block">
        <mat-icon class="preview-icon" (click)="previewFile()">visibility</mat-icon>
        <mat-icon class="download-icon" (click)="downloadFile()">file_download</mat-icon>
        <mat-icon class="delete-icon" (click)="deleteFile(imagePropertyPath)">delete</mat-icon>
      </div>
    </div>
  </div>
</div>
<ng-template #list>
  <div class="background-input-wrapper">
    <div class="file-uploader">
      <div class="dropzone-wrapper">
        <div class="dropzone" fileDragDrop (filesChangeEmiter)="addImageToList($event)">
          <input #input type="file" name="file" accept=".svg, .png, .jpg, .jpeg, .webp, .mp4, .gif" id="file"
            (change)="addImageToList($event)" multiple>
          <div class="file-upload-info">
            <mat-icon svgIcon="cloud"></mat-icon>
            <div class="upload-text">
              <span class="drop-target">Select a file or drag and drop here</span>
              <span class="file-type-info">JPG, JPEG, PNG ,WEBP ,SVG,GIF OR MP4, file size no more than 10mb</span>
            </div>
            <div class="outlined-button" (click)="input.click()">Select</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>