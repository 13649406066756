<div class="icon-button-wrapper">
  <span class="title" *ngIf="!isIconInputsShown">{{title}}</span>
  <button *ngIf="!childForm?.get('iconName')?.value && !isIconInputsShown" (click)="showIconInputs()"
    class="select-icon-button" mat-raised-button color="primary">SELECT
    ICON</button>
</div>

<ng-template [ngIf]="!isIconInputsShown && formRawValue?.iconName && childForm">
  <ng-container>
    <form [formGroup]="childForm">
      <div class="inputs-group-row">
        <div class="input-group">
          <div *ngIf="formRawValue" class="setted-icon-container">
            <mat-icon class="selected-icon">{{formRawValue.iconName}}</mat-icon>
            <div class="icon-properties-container">
              <span class="selected-icon-name-title">Icon name</span>
              <span class="icon-size">{{formRawValue.iconSize}}</span>
            </div>
            <div class="delete-button">
              <button (click)="removeIcon()" mat-icon-button><mat-icon svgIcon="delete"></mat-icon></button>
            </div>
          </div>
          <mat-form-field appearance="outline" class="size-picker">
            <mat-label>Icon size</mat-label>
            <mat-select formControlName="iconSize" class="select">
              <mat-option *ngFor="let item of iconSize" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-color-picker [position]="colorPickerPosition" formControlNameValue="iconColor" name="colorPickerField"
            ngDefaultControl></app-color-picker>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
<ng-template [formGroup]="childForm" #iconSearch [ngIf]="isIconInputsShown">
  <mat-form-field appearance="outline">
    <mat-label>Icon name</mat-label>
    <input formControlName="iconName" matInput type="text">
    <button *ngIf="formRawValue.iconName" (click)="clearValue()" matSuffix mat-icon-button aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>
    <mat-icon matSuffix *ngIf="!formRawValue.iconName">search</mat-icon>
  </mat-form-field>

  <div class="icons-container">
    <div class="icon-wrapper" *ngFor="let item of getFilteredArray()">
      <div matRipple class="icon-container" (click)="selectCurrentIcon(item)">
        <mat-icon class="material-symbols-outlined">{{item}}</mat-icon>
      </div>
      <div class="icon-name">{{item}}</div>
    </div>
  </div>
</ng-template>