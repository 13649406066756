import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QueryBuilderConfig } from 'angular2-query-builder';

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class QueryBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  query = {
    condition: 'and',
    rules: [
    ]
  };
   
  config: QueryBuilderConfig = {
    fields: {
      points: {name: 'points', type: 'string', nullable: true, operators: ['=', '<=', '>']},
      tierLevel: {name: 'tierLevel', type: 'string', nullable: true, operators: ['=', '<=', '>']},
      offerType: {name: 'offerType', type: 'string', nullable: true, operators: ['=', '<=', '>']},
    }
  }
}
