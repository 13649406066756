export const VOUCHER_HTML = (userData: string) => {
  return `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <style>
    body {
      font-family: sans-serif;
    }
    h1 {
      font-size: 32px;
      text-align: center;
      margin: 0 0 5px;
    }
    h2 {
      font-size: 24px;
      text-align: center;
      margin: 0 0 15px;
    }
    h3 {
      font-size: 16px;
      text-align: center;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 18px;
      margin: 0;
    }
    h4 {
      font-size: 22px;
      text-align: center;
      margin: 0 0 5px;
    }
    h5 {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      margin: 0;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    .title {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .center {
      text-align: center;
    }
  </style>
</head>
<body>
    <h1>${userData}</h1>
    <h2>Promotion Voucher</h2>
    <h3>Voucher #: <b>{{voucher}}</b></h3>
    <br />
    <br />
    <p>{{nowDate}}</p>
    <p>Patron's name: {{patronName}}</p>
    <p>Member Number: {{memberNumber}}</p>
    <br />
    <p class="title">{{promotionName}}</p>
    <p>{{promotionDescription}}</p>
    <br />
    <p>Expires: {{expiresDate}}</p>
    <br />
    <br />
    <h4>Terms and Conditions</h4>
    <p class="center">{{termsAndConditions}}</p>
    <br />
    <br />
    <br />
    <img src="{{posCodeImage}}">
</body>
</html>`;
};

export const VOUCHER_TYPES = ['QR Code', 'Barcode'];
