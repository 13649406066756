import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public isSidebarToggled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public kioskId$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }

}
