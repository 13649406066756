<app-control-panel [disableState]="getValidityState" [showResetButton]="true" (onResetClick)="resetForm()"
  (onCancelClick)="cancel()" (onSaveClick)="saveFormValues()"></app-control-panel>
<form [formGroup]="formGroup">
  <div class="main-wrapper">
    <div class="main-content" *ngIf="!isLoading;else loader">
      <div class="control-container">
        <app-background-input [form]="getNamedFormGroup('backgroundPrimary')" title="Background primary"
          [showColorPicker]="true" colorPropertyPath="color" imagePropertyPath="imageLink"></app-background-input>
      </div>
      <div class="control-container">
        <app-background-input title="Background Secondary" [form]="getNamedFormGroup('backgroundSecondary')"
          [showColorPicker]="true" colorPropertyPath="color" imagePropertyPath="imageLink"></app-background-input>
      </div>
      <div class="control-container" formGroupName="borders">
        <span class="control-title">Border color</span>
        <div class="control-wrapper">
          <div>
            <app-color-picker [formControlNameValue]="'color'" [form]="getNamedFormGroup('borders')"
              name="colorPickerField"></app-color-picker>
          </div>
          <div class="border-controls">
            <div class="control">
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Radius</mat-label>
                <input matInput type="number" formControlName="radius">
              </mat-form-field>
            </div>
            <div class="control">
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Border width</mat-label>
                <input matInput type="number" formControlName="width">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="control-container" formGroupName="scrollSettings">
        <span class="control-title">Scrollbar settings</span>
        <div class="control-wrapper">
          <div>
            <app-color-picker [formControlNameValue]="'color'" [form]="getNamedFormGroup('scrollSettings')"
              name="colorPickerField"></app-color-picker>
          </div>
          <div class="border-controls">
            <div class="control">
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Border Radius</mat-label>
                <input matInput type="number" formControlName="radius">
              </mat-form-field>
            </div>
            <div class="control">
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Scrollbar width</mat-label>
                <input matInput type="number" formControlName="width">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="control-container" formGroupName="textSettings">
        <span class="control-title">Text settings</span>
        <mat-form-field appearance="outline">
          <mat-label>Font family</mat-label>
          <mat-select class="select" formControlName="fontFamily">
            <mat-option *ngFor="let item of fontFamily" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="control-container" *ngFor="let item of heading; let index" formGroupName="textSettings">
        <span class="control-title">Heading {{index}}</span>
        <div class="controls" [formGroupName]="'heading' + index">
          <mat-form-field appearance="outline">
            <mat-label>Font weight</mat-label>
            <mat-select formControlName="fontWeight" class="select form-field">
              <mat-option *ngFor="let item of fontWeight" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Font size</mat-label>
            <input matInput type="number" formControlName="fontSize">
          </mat-form-field>
        </div>
      </div>
      <div class="control-container" formGroupName="textSettings">
        <span class="control-title">Main Text</span>
        <div class="controls" formGroupName="mainText">
          <mat-form-field appearance="outline">
            <mat-label>Font weight</mat-label>
            <mat-select formControlName="fontWeight" class="select form-field">
              <mat-option *ngFor="let item of fontWeight" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Font size</mat-label>
            <input matInput type="number" formControlName="fontSize">
          </mat-form-field>
        </div>
      </div>
      <div class="control-container">
        <span class="control-title">Loader</span>
        <div class="controls loader">
          <app-background-input [form]="formGroup.get('loader')" [showColorPicker]="false" colorPropertyPath="color"
            imagePropertyPath="imageLink"></app-background-input>
        </div>
      </div>
      <div class="control-container" formGroupName="textSettings">
        <span class="control-title">Help Text</span>
        <div class="controls" formGroupName="helpText">
          <mat-form-field appearance="outline">
            <mat-label>Font weight</mat-label>
            <mat-select formControlName="fontWeight" class="select form-field">
              <mat-option *ngFor="let item of fontWeight" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Font size</mat-label>
            <input matInput type="number" formControlName="fontSize">
          </mat-form-field>
        </div>
      </div>

      <div class="control-container">
        <span class="control-title">Buttons Color</span>
        <div class="controls">
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'primaryColor'" [form]="getNamedFormGroup('buttonsColor')"
                name="colorPickerField"></app-color-picker>
            </div>
            <span>Primary</span>
          </div>
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'secondaryColor'" [form]="getNamedFormGroup('buttonsColor')"
                name="colorPickerField" ngDefaultControl></app-color-picker>
            </div>
            <span>Secondary</span>
          </div>
        </div>
      </div>
      <div class="control-container">
        <span class="control-title">Status Color</span>
        <div class="controls">
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'info'" [form]="getNamedFormGroup('statusColor')"
                name="colorPickerField" ngDefaultControl></app-color-picker>
            </div>
            <span>Info</span>
          </div>
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'warning'" [form]="getNamedFormGroup('statusColor')"
                name="colorPickerField" ngDefaultControl></app-color-picker>
            </div>
            <span>Warning</span>
          </div>
        </div>
      </div>
      <div class="control-container">
        <span class="control-title"></span>
        <div class="controls">
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'success'" [form]="getNamedFormGroup('statusColor')"
                name="colorPickerField" ngDefaultControl></app-color-picker>
            </div>
            <span>Success</span>
          </div>
          <div class="color-wrapper">
            <div>
              <app-color-picker [formControlNameValue]="'error'" [form]="getNamedFormGroup('statusColor')"
                name="colorPickerField" ngDefaultControl></app-color-picker>
            </div>
            <span>Error</span>
          </div>
        </div>
      </div>
      <div class="control-container">
        <span class="control-title">Css override</span>
        <mat-form-field appearance="outline" autocomplete="nope" style="height:452px">
          <mat-label>Css override</mat-label>
          <textarea matInput formControlName="cssOverride" placeholder="Css override" rows="17"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<ng-template #loader>
  <app-loader [diameter]="100"></app-loader>
</ng-template>