import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { iconSize, matIcons } from '../../../core/constants/constants';

@Component({
  selector: 'app-icons-picker',
  templateUrl: './icons-picker.component.html',
  styleUrls: ['./icons-picker.component.scss']
})
export class IconsPickerComponent implements OnInit {

  @Output()
  public onIconSearchOpen: EventEmitter<any> = new EventEmitter()

  @Input()
  public value: string = '';

  @Input()
  public formGroupNameValue: string = '';

  @Input()
  public state: any

  @Input()
  public isIconInputsShown = false;

  @Input()
  public form: any;

  @Input()
  public colorPickerPosition: any;

  @Input()
  title: string = 'Icon';

  public matIcons: string[] = matIcons;

  public iconSize = iconSize;

  public childForm: any;

  public get formRawValue() {
    return this.childForm?.getRawValue();
  }

  constructor(private parentFormGroup: FormGroupDirective) { }

  ngOnInit() {
    if (this.form && this.formGroupNameValue) {
      this.childForm = this.form.get(this.formGroupNameValue);
    } else if (this.formGroupNameValue) {
      this.childForm = this.parentFormGroup.control.get(this.formGroupNameValue) as FormGroup;
    } else {
      if (this.form) {
        this.childForm = this.form;
      } else {
        this.childForm = this.parentFormGroup.form;
      }
    }
  }

  idTrackFn = (item: any) => item;

  showIconInputs() {
    this.onIconSearchOpen.emit()
    this.isIconInputsShown = true;
  }

  public clearValue() {
    (this.childForm as FormControl).get('iconName')?.setValue('');
  }

  public getFilteredArray(): string[] {
    return matIcons.filter(icon => icon.includes((this.childForm as FormControl).get('iconName')?.value))
  }

  public selectCurrentIcon(icon: string) {
    (this.childForm as FormControl).get('iconName')?.setValue(icon);
    this.childForm.markAsDirty();
  }

  public removeIcon() {
    (this.childForm as FormControl).get('iconName')?.setValue('');
    (this.childForm as FormControl).get('iconSize')?.reset();
    (this.childForm as FormControl).get('iconColor')?.reset();
  }

}
