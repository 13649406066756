export const CMS_VOUCHER_HTML = (userData: string) => {
    return `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        body {
            font-family: sans-serif;
        }

        h1 {
            font-size: 32px;
            text-align: center;
            margin: 0 0 5px;
        }

        h2 {
            font-size: 24px;
            text-align: center;
            margin: 0 0 15px;
        }

        h3 {
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            margin: 0;
        }

        p {
            font-size: 18px;
            margin: 0;
        }

        h4 {
            font-size: 22px;
            text-align: center;
            margin: 0 0 5px;
        }

        h5 {
            font-size: 22px;
            font-weight: 400;
            text-align: center;
            margin: 0;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .title {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .center {
            text-align: center;
        }

        .valley-view-image {
            width: 100%;
            height: auto;
        }

        .line {
            border: none;
            height: 3px;
            background-color: #000;
        }

        .element {
            display: flex;
            justify-content: space-between;
        }
        .element span:first-child {
            width: 30%;
        } 
        .element span:not(:first-child) {
            width: 70%;
        }
        .bar-code {
            width: 100%;
            height: 10vh;
        }
    </style>
</head>

<body>
    <img class="bar-code" src="{{posCodeImage}}">
    <br />
    <br />
    <img class="valley-view-image" src="{{voucherImage}}" alt="">
    <br />
    <br />
    <p style="text-align: center;"><strong>{{title}}</strong></p>
    <br />
    <br />
    <hr class="line">
    <br />
    <p class="element"><span>Player ID:</span> <span>{{playerId}}</span></p>
    <br />
    <p class="element">
        <span>Player Name:</span> <span>{{playerName}}</span>
    </p>
    <br />
    <p class="element">
        <span>Ticket #:</span> <span>{{ticketNumber}}</span>
    </p>
    <br />
    <p class="element">
        <span>Issue Date:</span> <span>{{nowDate}}{{nowDate12h}}</span>
    </p>
    <br />
    <p class="element">
        <span>Kiosk Device Id:</span> <span>{{kioskDeviceId}}</span>
    </p>
    <br />
    <hr class="line">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p>Valid for one {{title}}</p>
    <p>Please present with valid ID</p>
    <p>Valid for 24 hours</p>
    <p>Non-transferable</p>
    <p>For use by card holder</p>
    <p>Gratuity not included</p>
    <p>No cash value</p>
    <br />
    <br />
    <p>Thank you</p>
</body>

</html>
    `

}


